// Based on https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.ts
import { Fragment, type NodeType, Slice } from '@tiptap/pm/model';
import type { Command } from '@tiptap/pm/state';
import { ReplaceAroundStep } from '@tiptap/pm/transform';

export function sinkListItem(itemType: NodeType): Command {
  return function (state, dispatch) {
    const { $from, $to } = state.selection;

    // This fixes a bug in the original code where if you defined that a bullet list can contain both list items and task items
    // Like we do: https://github.com/swimmio/swimm/blob/7ca53e6915a0d9e8f9faa8adbf784f4535ac06ee/packages/swmd/src/swmd/extensions.ts#L59
    // then this fails to indent nested lists
    const range = $from.blockRange($to, (node) => node.childCount > 0 && !!node.type.contentMatch.matchType(itemType));
    if (!range) {
      return false;
    }
    const startIndex = range.startIndex;
    if (startIndex === 0) {
      return false;
    }
    const parent = range.parent,
      nodeBefore = parent.child(startIndex - 1);
    if (nodeBefore.type !== itemType) {
      return false;
    }

    if (dispatch) {
      const nestedBefore = nodeBefore.lastChild && nodeBefore.lastChild.type === parent.type;
      const inner = Fragment.from(nestedBefore ? itemType.create() : null);
      const slice = new Slice(
        Fragment.from(itemType.create(null, Fragment.from(parent.type.create(null, inner)))),
        nestedBefore ? 3 : 1,
        0
      );
      const before = range.start,
        after = range.end;
      dispatch(
        state.tr
          .step(new ReplaceAroundStep(before - (nestedBefore ? 3 : 1), after, before, after, slice, 1, true))
          .scrollIntoView()
      );
    }
    return true;
  };
}
