import { DUMMY_REPO_ID, isRepoIdDummyRepo } from '../demoData';
import state from '../shared-local-state';
import { GitAuthType, GitCloudProviderName, GitProviderName, HOSTING_NAME_TO_PROVIDER, RepoStateData } from '../types';
import { BASE_URL, SWIMM_TEMPLATES_REPO_DATA } from '../config';
import gitwrapper from 'GitUtils/gitwrapper';
import { isEqual } from 'lodash-es';
import { UrlUtils } from '../utils/url-utils';
import gitUrlParse from 'git-url-parse';
import { refreshToken } from './refresh-tokens';
import axios from 'axios';
import { getLoggerNew } from '#logger';

const logger = getLoggerNew("packages/shared/src/git-utils/git-provider-utils.ts");

const REPO_LOCAL_STATE_DATA_REQUIRED_PROPS = Object.freeze(['provider', 'owner', 'repoName']);
const PROVIDERS_NOT_SUPPORTING_RENAME = [GitProviderName.BitbucketDc];
const PROVIDERS_NOT_SUPPORTING_PR_TO_DOC = [GitProviderName.AzureDevops];
const PROVIDERS_THAT_USE_GETREPOTREE_CLOUDRUN = [
  GitProviderName.GitLabEnterprise,
  GitProviderName.GitLab,
  GitProviderName.Bitbucket,
];
const PROVIDERS_THAT_REFRESH_TOKEN_LOCALLY = [GitProviderName.GitLabEnterprise, GitProviderName.BitbucketDc];
const PROVIDERS_THAT_SUPPORT_MINIMIZE_COMMENTS = [GitProviderName.GitHub, GitProviderName.GitHubEnterprise];
const PROVIDERS_THAT_DONT_SUPPORT_SHARING_INTERNALLY = [GitProviderName.AzureDevops];
export const BITBUCKET_DC_PLUGIN_OAUTH_ROUTE = '/swimmapi/latest/oauth';
// GitHub cloud has the GitHub app as it's CI
const PROVIDERS_WITHOUT_CI_SUPPORT = [GitProviderName.GitHubEnterprise, GitProviderName.AzureDevops];

const DEFAULT_BITBUCKET_HOSTNAME = 'bitbucket.org';
const DEFAULT_AZURE_DEVOPS_DEV_HOSTNAME = 'dev.azure.com';
const DEFAULT_GITLAB_HOSTNAME = 'gitlab.com';
const DEFAULT_GITHUB_HOSTNAME = 'github.com';
const DEFAULT_GITHUB_API = 'https://api.github.com';
const DEFAULT_GITLAB_API = `https://${DEFAULT_GITLAB_HOSTNAME}`;

const AZURE_BASE_URLS = {
  ACCOUNT: 'https://app.vssps.visualstudio.com',
  CODE: `https://${DEFAULT_AZURE_DEVOPS_DEV_HOSTNAME}`,
} as const;

const CLOUD_PROVIDER_TO_DEFAULT_HOSTNAME = {
  [GitProviderName.GitHub]: DEFAULT_GITHUB_HOSTNAME,
  [GitProviderName.GitLab]: DEFAULT_GITLAB_HOSTNAME,
  [GitProviderName.Bitbucket]: DEFAULT_BITBUCKET_HOSTNAME,
  [GitProviderName.AzureDevops]: DEFAULT_AZURE_DEVOPS_DEV_HOSTNAME,
};

export interface StateTokenData {
  token: string;
  refresh_token?: string;
  created_at?: number;
  orgs: string[];
}

export interface BitbucketDatacenterOauthParams {
  client_id: string;
  client_secret: string;
  grant_type: 'authorization_code' | 'refresh_token';
  refresh_token?: string;
  code?: string;
  state?: string;
}

export function isProviderCloud(provider) {
  return Object.values(GitCloudProviderName).includes(provider);
}

export function providerOwnerHasProject(provider: GitProviderName) {
  // also azure server in the future
  return provider === GitProviderName.AzureDevops;
}

async function isUserOrgIdentical(authorizedOrgsFromState, isEnterprise, driverConfig, trackAuthorize) {
  try {
    const authorizedOrgs = await gitwrapper.getUserOrganizations(
      isEnterprise ? GitProviderName.GitHubEnterprise : GitProviderName.GitHub,
      driverConfig
    );
    const isUserOrgsIdentical = isEqual(authorizedOrgsFromState, authorizedOrgs);

    if (!isUserOrgsIdentical) {
      trackAuthorize?.({ Reason: 'Org Access Changed' });
    }

    return isUserOrgsIdentical;
  } catch (error) {
    trackAuthorize?.({ Reason: 'Fetch failed' });
    return false;
  }
}

async function isSamlAccessOK(repoId, trackAuthorize) {
  try {
    await gitwrapper.getRepoRemoteData({ repoId });
    return true;
  } catch (error) {
    const isSmal = error.message.includes('SAML');

    if (isSmal) {
      trackAuthorize?.({ Reason: 'SAML Access Changed' });
    }

    return !isSmal;
  }
}

async function verifyGithubToken({ repoId, gitHostingUrl, driverConfig, trackAuthorize }) {
  try {
    const isEnterprise = !!driverConfig?.baseUrl;
    const githubToken = await getGitHostingToken(gitHostingUrl);
    if (!githubToken) {
      return false;
    }
    const authorizedOrgsFromState = await getHostingsTokenOrgsFromState(gitHostingUrl);
    if (authorizedOrgsFromState?.length === 0) {
      // if it doesn't exist in local state the token is new, so we hadn't set it yet
      return true;
    }

    const results = await Promise.allSettled([
      isUserOrgIdentical(authorizedOrgsFromState, isEnterprise, driverConfig, trackAuthorize),
      isSamlAccessOK(repoId, trackAuthorize),
    ]);

    return (
      (results[0] as PromiseFulfilledResult<boolean>).value && (results[1] as PromiseFulfilledResult<boolean>).value
    );
  } catch (error) {
    return false;
  }
}

async function verifyGitToken({ provider, driverConfig, trackAuthorize }) {
  logger.info(
    `Verifying Git token for ${provider} with config: ${JSON.stringify({
      baseUrl: driverConfig.baseUrl,
      tenantId: driverConfig.tenantId,
    })}`
  );
  try {
    await gitwrapper.getUserOrganizations(provider, driverConfig);
    logger.info(`Token for ${provider} is valid`);
    return true;
  } catch (err) {
    logger.error({ err }, `Token for ${provider} is invalid: ${err.message}`);
    trackAuthorize?.({ Reason: 'Fetch failed' });
    return false;
  }
}

const gitProviderToVerifyTokenMap = {
  [GitProviderName.GitHub]: verifyGithubToken,
  [GitProviderName.GitHubEnterprise]: verifyGithubToken,
  [GitProviderName.GitLab]: verifyGitToken,
  [GitProviderName.GitLabEnterprise]: verifyGitToken,
  [GitProviderName.Bitbucket]: verifyGitToken,
  [GitProviderName.BitbucketDc]: verifyGitToken,
  [GitProviderName.AzureDevops]: verifyGitToken,
  [GitProviderName.Testing]: verifyGitToken,
};

export async function getVerifiedGitHostingToken({
  provider,
  gitHostingUrl,
  tenantId,
  trackAuthorize,
}: {
  provider: GitProviderName;
  gitHostingUrl: string;
  tenantId?: string;
  trackAuthorize: (payload: { Reason: string }) => void;
}) {
  const gitHostingKey = UrlUtils.getGitHostingKey({ provider, gitUrl: gitHostingUrl, tenantId });
  logger.info(`Getting verified token for ${provider} with key: ${gitHostingKey}`);
  const token = await getGitHostingToken(gitHostingKey);
  if (!token) {
    logger.info(`No token found for ${provider} with key: ${gitHostingKey}`);
    return null;
  }

  const isCloudProvider = isProviderCloud(provider);
  const repos = await state.get({ key: 'repos', defaultValue: {} });
  logger.info(
    `Finding relevant repos for ${provider} with gitHostingUrl: ${gitHostingUrl} and tenantId: ${tenantId ?? 'null'}`
  );
  const relevantRepoIds = Object.keys(repos).filter((repoId) => {
    if (isRepoIdDummyRepo(repoId)) {
      return false;
    }

    const repoData = repos[repoId];

    if (!isCloudProvider) {
      return (repoData.api_url?.includes(gitHostingUrl) ?? false) && repoData.provider === provider;
    }

    if (provider === GitProviderName.AzureDevops) {
      if (gitHostingUrl != null && !!tenantId) {
        // We are in azure cloud multi tenant and should work with the custom authentication flow
        const isRelevant =
          (repoData.api_url?.includes(gitHostingUrl) ?? false) &&
          repoData.provider === provider &&
          repoData.tenant_id === tenantId;
        logger.info(
          `Is repo ${repoId}:${JSON.stringify({
            provider: repoData.provider,
            api_url: repoData.api_url,
            tenant_id: repoData.tenant_id,
          })} relevant for ${provider} with gitHostingUrl ${gitHostingUrl} and tenantId: ${tenantId} ? ${isRelevant}`
        );
        return isRelevant;
      } else {
        // When user has both multi-tenant and non-multi-tenant repositories in state only checking the provider risks returning the wrong repo
        return repoData.api_url == null && repoData.provider === provider && repoData.tenant_id == null;
      }
    }

    return repoData.provider === provider;
  });

  if (relevantRepoIds.length === 0) {
    logger.info(`No relevant repos found for ${provider} with gitHostingKey: ${gitHostingKey}`);
    return token;
  }

  const matchedRepoData = repos[relevantRepoIds[0]];
  logger.info(
    `Found relevant repo for ${provider} with gitHostingKey: ${gitHostingKey}, ${relevantRepoIds[0]}: ${JSON.stringify({
      provider: matchedRepoData.provider,
      api_url: matchedRepoData.api_url,
      tenant_id: matchedRepoData.tenant_id,
    })}`
  );
  const driverConfig = matchedRepoData.api_url
    ? { baseUrl: matchedRepoData.api_url, tenantId: matchedRepoData.tenant_id }
    : {};

  if (
    await gitProviderToVerifyTokenMap[provider]({
      repoId: relevantRepoIds[0],
      gitHostingUrl,
      provider,
      driverConfig,
      trackAuthorize,
    })
  ) {
    return token;
  } else {
    logger.info(
      `Token for ${provider} with key: ${gitHostingKey} is invalid. Deleting key ${UrlUtils.slugUrlForConfig(
        gitHostingKey
      )}`
    );
    await state.deleteKey({ key: `git_tokens.${UrlUtils.slugUrlForConfig(gitHostingKey)}`, isGlobalKey: true });
    gitwrapper.deleteTokenFromMemory(provider, driverConfig);
    return null;
  }
}

export async function getGitHostingToken(gitHostingKey: string) {
  const tokenData = await state.get({ key: 'git_tokens', defaultValue: {} });
  logger.info(
    `Getting token for git hosting key: ${gitHostingKey}. Tokens available for: ${JSON.stringify(
      Object.keys(tokenData)
    )}`
  );
  return tokenData[UrlUtils.slugUrlForConfig(gitHostingKey)]?.token || null;
}

export async function getGitHostingData(gitHostingUrl: string): Promise<StateTokenData | null> {
  const tokenData = await state.get({ key: 'git_tokens', defaultValue: {} });
  return tokenData[UrlUtils.slugUrlForConfig(gitHostingUrl)] || null;
}

export async function getAllAuthorizedGitHostings(): Promise<string[]> {
  const tokenData = await state.get({ key: 'git_tokens', defaultValue: {} });
  return Object.keys(tokenData);
}

export async function setGitHostingTokenData(gitHostingKey: string, data: StateTokenData) {
  const sluggedHostname = UrlUtils.slugUrlForConfig(gitHostingKey);
  const existingData = (await state.get({ key: `git_tokens.${sluggedHostname}`, defaultValue: {} })) as StateTokenData;
  await state.set({ key: `git_tokens.${sluggedHostname}`, value: { ...existingData, ...data } });
}

export async function setAuthorizedOrgs(organizations: string[] = [], gitHostingKey: string) {
  return state.set({ key: `git_tokens.${UrlUtils.slugUrlForConfig(gitHostingKey)}.orgs`, value: organizations });
}

export async function getHostingsTokenOrgsFromState(gitHostingUrl) {
  const tokenData = await state.get({ key: 'git_tokens', defaultValue: {} });
  return tokenData[UrlUtils.slugUrlForConfig(gitHostingUrl)]?.orgs || [];
}

/**
 * This function returns the git provider token only for client callers and allowed cloud callers.
 * The token is a sensitive piece of information, only for whitelisted services and functions that runs
 * on the client side.
 * @param {GitProviderName} providerName the provider name (github, gitlab, etc.)
 * @param {boolean} isClient true if the caller function runs only on the client side. otherwise, false.
 * @param {string} callerName the cloud function / cloud run name that will eventually use the token.
 * @param {string} repoId in case repo id exists, to allow calls to public repos with empty token
 * @returns a promise that will resolve to the git provider token, or a thrown error.
 */
export async function getGitProviderToken({
  gitHostingUrl,
  isClient,
  callerName = '',
  repoId,
}: {
  gitHostingUrl: string;
  isClient: boolean;
  callerName?: string;
  repoId?: string;
}) {
  const CALLERS_WHITELIST = ['sgd-discover', 'list-all-files-remote'];
  if (!isClient) {
    if (!CALLERS_WHITELIST.includes(callerName)) {
      throw new Error(`Caller not allowed, caller name: ${callerName}`);
    }
  }
  if (repoId === DUMMY_REPO_ID) {
    return '';
  }
  const key = await getGitHostingToken(gitHostingUrl);
  if (!key) {
    return null;
  }
  return key;
}

export async function getRepoStateData(repoId: string): Promise<RepoStateData> {
  const templatesRepoData = SWIMM_TEMPLATES_REPO_DATA;
  if (repoId === templatesRepoData.repoId) {
    return templatesRepoData;
  }
  if (typeof repoId !== 'string') {
    throw new Error(
      `Cannot get repo data from local state, repoId expected to be of type string, but got: ${typeof repoId}`
    );
  } else if (repoId.length === 0) {
    throw new Error(`Cannot get repo data from local state, repoId is an empty string`);
  }
  const repoData = await state.getRepoFromLocalState(repoId);
  if (!repoData) {
    throw new Error(`No repo data in local state for repoId: ${repoId}`);
  }
  for (const prop of REPO_LOCAL_STATE_DATA_REQUIRED_PROPS) {
    const value = repoData[prop];
    if (!value) {
      throw new Error(
        `Local state repo data has an invalid value for property "${prop}", repoId: ${repoId}, value: ${value}`
      );
    }
  }
  return repoData;
}

export type ParsedGitProviderURL = gitUrlParse.GitUrl & { original_owner: string };

export function gitUrlParseWrapper(gitUrl: string) {
  // a wrapper around gitUrlParse to handle spaces 86bxtcc8n
  const escapedUrl = gitUrl.replace(/[()]/g, function (match) {
    return '%' + match.charCodeAt(0).toString(16).toUpperCase();
  });
  return gitUrlParse(escapedUrl);
}

export function parseGitProviderURL(gitUrl: string): ParsedGitProviderURL {
  const parseResult = gitUrlParseWrapper(gitUrl);
  // `organization` and `owner` are identical in all major providers _except_ Azure.
  // We started by defining the owner as the organization for Azure
  // but now we fake the owner to be org/project
  const provider = getProviderFromResource(parseResult.resource);
  // this will not work well when we develop azure server, since we cannot get the provider from the url
  // in this case. we will need to find a way to solve this
  const shouldAddProjectToOwner = provider != null && providerOwnerHasProject(provider);
  const owner = shouldAddProjectToOwner ? `${parseResult.organization}/${parseResult.owner}` : parseResult.owner;
  return { ...parseResult, owner, original_owner: parseResult.owner };
}

export function getUtcTimeInSeconds() {
  return Math.floor(new Date().getTime() / 1000);
}

function shouldRefreshTokenLocally(provider: GitProviderName) {
  return canProviderRefreshTokenLocally(provider) && localStorage.getItem('refreshInfo') != null;
}

async function authenticate(endpoint, body) {
  try {
    const response = await axios.post(`${endpoint}/refresh-token`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    return null;
  }
}

export async function authenticateBitbucketDcWithPlugin(
  hostname: string,
  bitbucketOauthParams: BitbucketDatacenterOauthParams
) {
  try {
    const endpoint = `https://${hostname}/rest${BITBUCKET_DC_PLUGIN_OAUTH_ROUTE}`;
    logger.info(
      `Sending local auth request (${bitbucketOauthParams?.grant_type}) to Bitbucket DC plugin at ${endpoint}`
    );

    const response = await axios.post(endpoint, {
      ...bitbucketOauthParams,
      redirect_uri: `${BASE_URL}/localGitProviderAuth`,
    });
    return {
      token: response?.data?.access_token,
      refresh_token: response?.data?.refresh_token,
      created_at: getUtcTimeInSeconds(),
    };
  } catch (err) {
    logger.error(
      { err },
      `Error ${
        bitbucketOauthParams?.grant_type === 'refresh_token' ? 'refreshing token' : 'authenticating'
      } with Bitbucket DC plugin for host ${hostname}: ${err}`
    );
    return null;
  }
}

async function refreshTokenLocally(provider: GitProviderName, hostname: string, refreshToken: string) {
  const {
    clientId,
    clientSecret,
    authenticationEndpoints: endPoints,
    port,
  } = JSON.parse(localStorage.getItem('refreshInfo') ?? '{}');

  if (provider !== GitProviderName.BitbucketDc) {
    const body = {
      clientId,
      clientSecret,
      gitHosting: hostname,
      provider,
      refreshToken,
    };

    let response;
    for (const endpoint of endPoints) {
      response = await authenticate(endpoint, body);
      if (response) {
        break;
      }
    }
    return response;
  }
  const resolvedHostname = port ? `${hostname}:${port}` : hostname;
  return await authenticateBitbucketDcWithPlugin(resolvedHostname, {
    client_id: clientId,
    client_secret: clientSecret,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });
}

export async function refreshAuthToken({
  provider,
  hostname,
  tenantId,
  expiryTimeInSeconds,
  authType,
}: {
  provider: GitProviderName;
  hostname: string;
  tenantId?: string;
  expiryTimeInSeconds: number;
  authType?: GitAuthType;
}) {
  const gitHostingKey = UrlUtils.getGitHostingKey({ provider, gitUrl: hostname, tenantId });
  const hostingData = await getGitHostingData(gitHostingKey);
  const logPayload = {
    provider,
    hostname,
    tenantId: tenantId ?? 'undefined',
  };

  if (!hostingData?.refresh_token || !hostingData?.created_at) {
    logger.info(
      logPayload,
      `In refreshAuthToken return null since has hostingData=${!!hostingData} has hostingData.refreshToken=${!!hostingData?.refresh_token} has hostingData.createdAt=${!!hostingData?.created_at}`
    );
    return null;
  }

  const now = getUtcTimeInSeconds();
  if (now < hostingData.created_at + expiryTimeInSeconds) {
    logger.info(
      logPayload,
      `In refreshAuthToken return null since ${hostingData.created_at}+${expiryTimeInSeconds}<${now}`
    );
    return null;
  }

  let data;
  if (shouldRefreshTokenLocally(provider)) {
    data = await refreshTokenLocally(provider, hostname, hostingData.refresh_token);
  } else {
    const overrideSecretName =
      [GitProviderName.GitHub, GitProviderName.GitHubEnterprise].includes(provider) && authType === 'github_app'
        ? 'GITHUB_APP_AUTH'
        : undefined;
    ({ data } = await refreshToken({
      provider,
      gitHosting: hostname,
      tenantId,
      refreshToken: hostingData.refresh_token,
      secretName: overrideSecretName,
    }));
  }

  if (data) {
    await setGitHostingTokenData(gitHostingKey, data);
  }
  if (!data?.token) {
    logger.info(logPayload, `In refreshAuthToken data?.token is null`);
  }
  return data?.token ?? null;
}

export function doesProviderSupportRename(provider: GitProviderName) {
  return !PROVIDERS_NOT_SUPPORTING_RENAME.includes(provider);
}

export function doesProviderSupportPRToDoc(provider: GitProviderName) {
  return !PROVIDERS_NOT_SUPPORTING_PR_TO_DOC.includes(provider);
}

export function doesProviderRequireCloudGetRepoTree(provider: GitProviderName) {
  return PROVIDERS_THAT_USE_GETREPOTREE_CLOUDRUN.includes(provider);
}

export function doesProviderSupportMinimizeComments(provider: GitProviderName) {
  return PROVIDERS_THAT_SUPPORT_MINIMIZE_COMMENTS.includes(provider);
}

export function doesProviderSupportSharingInternally(provider: GitProviderName) {
  return !PROVIDERS_THAT_DONT_SUPPORT_SHARING_INTERNALLY.includes(provider);
}

export function doesProviderHaveCI(provider: GitProviderName) {
  return !PROVIDERS_WITHOUT_CI_SUPPORT.includes(provider);
}

export function canProviderRefreshTokenLocally(provider: GitProviderName) {
  return PROVIDERS_THAT_REFRESH_TOKEN_LOCALLY.includes(provider);
}

export function getPendingSetupMessage(providerLongDisplayName: string) {
  const prefix = providerLongDisplayName ?? 'Your Workspace';
  return {
    text: `${prefix} requires an extra setup to add your repo(s). Contact our experts to get you up and running.`,
    callToAction: {
      buttonText: 'Contact us',
      action: () => {
        window.open('mailto:info@swimm.io', '_blank');
      },
    },
  };
}

export function getHostingNameByProvider(gitProviderName: GitProviderName): string {
  return Object.entries(HOSTING_NAME_TO_PROVIDER).find(([, provider]) => provider === gitProviderName)?.[0] || '';
}

export function getProviderFromResource(resource: string): GitProviderName | null {
  switch (resource) {
    case 'github.com':
      return GitProviderName.GitHub;
    case 'gitlab.com':
      return GitProviderName.GitLab;
    case 'bitbucket.org':
      return GitProviderName.Bitbucket;
    case 'dev.azure.com':
      return GitProviderName.AzureDevops;
    case 'ssh.dev.azure.com':
      return GitProviderName.AzureDevops;
  }
  return null;
}

export function splitOwner(owner: string): [string, string | null] {
  const splitIndex = owner.lastIndexOf('/');
  if (splitIndex >= 0) {
    return [owner.slice(0, splitIndex), owner.slice(splitIndex + 1)];
  }
  return [owner, null];
}

export default {
  DEFAULT_GITHUB_API,
  DEFAULT_GITLAB_API,
  DEFAULT_BITBUCKET_HOSTNAME,
  getVerifiedGitHostingToken,
  getGitHostingToken,
  doesProviderSupportPRToDoc,
  getPendingSetupMessage,
  getAllAuthorizedGitHostings,
  setGitHostingTokenData,
  getGitProviderToken,
  getRepoStateData,
  setAuthorizedOrgs,
  isProviderCloud,
  parseGitProviderURL,
  getUtcTimeInSeconds,
  refreshAuthToken,
  doesProviderSupportRename,
  AZURE_BASE_URLS,
  CLOUD_PROVIDER_TO_DEFAULT_HOSTNAME,
  doesProviderRequireCloudGetRepoTree,
  doesProviderSupportMinimizeComments,
  doesProviderSupportSharingInternally,
  doesProviderHaveCI,
  canProviderRefreshTokenLocally,
  authenticateBitbucketDcWithPlugin,
  getHostingNameByProvider,
  providerOwnerHasProject,
  splitOwner,
};
