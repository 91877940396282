// Based on https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.ts
import { Fragment, NodeRange, type NodeType, Slice } from '@tiptap/pm/model';
import type { Command } from '@tiptap/pm/state';
import { type EditorState, type Transaction } from '@tiptap/pm/state';
import { ReplaceAroundStep, canJoin, liftTarget } from '@tiptap/pm/transform';

function liftToOuterList(
  state: EditorState,
  dispatch: (tr: Transaction) => void,
  itemType: NodeType,
  range: NodeRange
) {
  const tr = state.tr,
    end = range.end,
    endOfList = range.$to.end(range.depth);
  if (end < endOfList) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    tr.step(
      new ReplaceAroundStep(
        end - 1,
        endOfList,
        end,
        endOfList,
        new Slice(Fragment.from(itemType.create(null, range.parent.copy())), 1, 0),
        1,
        true
      )
    );
    range = new NodeRange(tr.doc.resolve(range.$from.pos), tr.doc.resolve(endOfList), range.depth);
  }
  const target = liftTarget(range);
  if (target == null) {
    return false;
  }
  tr.lift(range, target);
  const after = tr.mapping.map(end, -1) - 1;
  if (canJoin(tr.doc, after)) {
    tr.join(after);
  }
  dispatch(tr.scrollIntoView());
  return true;
}

function liftOutOfList(state: EditorState, dispatch: (tr: Transaction) => void, range: NodeRange) {
  const tr = state.tr,
    list = range.parent;
  // Merge the list items into a single big item
  for (let pos = range.end, i = range.endIndex - 1, e = range.startIndex; i > e; i--) {
    pos -= list.child(i).nodeSize;
    tr.delete(pos - 1, pos + 1);
  }
  const $start = tr.doc.resolve(range.start),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    item = $start.nodeAfter!;
  if (tr.mapping.map(range.end) !== range.start + item.nodeSize) {
    return false;
  }
  const atStart = range.startIndex === 0,
    atEnd = range.endIndex === list.childCount;
  const parent = $start.node(-1),
    indexBefore = $start.index(-1);
  if (
    !parent.canReplace(
      indexBefore + (atStart ? 0 : 1),
      indexBefore + 1,
      item.content.append(atEnd ? Fragment.empty : Fragment.from(list))
    )
  ) {
    return false;
  }
  const start = $start.pos,
    end = start + item.nodeSize;
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(
    new ReplaceAroundStep(
      start - (atStart ? 1 : 0),
      end + (atEnd ? 1 : 0),
      start + 1,
      end - 1,
      new Slice(
        (atStart ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))).append(
          atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))
        ),
        atStart ? 0 : 1,
        atEnd ? 0 : 1
      ),
      atStart ? 0 : 1
    )
  );
  dispatch(tr.scrollIntoView());
  return true;
}

export function liftListItem(itemType: NodeType): Command {
  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    const { $from, $to } = state.selection;

    // This fixes a bug in the original code where if you defined that a bullet list can contain both list items and task items
    // Like we do: https://github.com/swimmio/swimm/blob/7ca53e6915a0d9e8f9faa8adbf784f4535ac06ee/packages/swmd/src/swmd/extensions.ts#L59
    // then this fails to un-indent nested lists
    const range = $from.blockRange($to, (node) => node.childCount > 0 && !!node.type.contentMatch.matchType(itemType));
    if (!range) {
      return false;
    }
    if (!dispatch) {
      return true;
    }

    if ($from.node(range.depth - 1).type === itemType) {
      // Inside a parent list
      return liftToOuterList(state, dispatch, itemType, range);
    } else {
      // This is the second part of the fix where if both itemType and $from.node(range.depth - 1).type are allowed to be children of the parent
      // But are not a match to each other (for instance - a list that contains both listItems and taskItems)
      // we want to pass handling to the next extension and not lift out
      if (
        range.parent.type.contentMatch.matchType(itemType) &&
        range.parent.type.contentMatch.matchType($from.node(range.depth - 1).type)
      ) {
        return false;
      }
      // Outer list node
      return liftOutOfList(state, dispatch, range);
    }
  };
}
