import { type ComputedRef, computed, ref } from 'vue';
import {
  type GitAuthType,
  GitProviderInfo,
  GitProviderName,
  UrlUtils,
  config,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
  productEvents,
} from '@swimm/shared';
import { AUTH_URL } from '@/config';
import { useStore } from 'vuex';
import { collectionNames, setValuesInDoc } from '@/adapters-common/firestore-wrapper';
import { useGetStartedMenu } from '@/modules/get-started/composables/useGetStartedMenu';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { GetStartedStepIds } from '@/modules/get-started/consts';
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import { openAsyncPopup } from '@/common/utils/popup-utils';

type buildUrlFuncType = ({
  gitHostingUrl,
  tenantId,
  shouldAllowAccessToPrivateRepos,
  redirect,
}: {
  gitHostingUrl: string;
  tenantId?: string;
  shouldAllowAccessToPrivateRepos: boolean;
  redirect: string;
}) => Promise<string>;

interface BasicConfig {
  client_id: string;
  should_authorize_locally?: string;
  port?: string;
}

export interface LocalAuthState {
  authName: string;
  workspaceId: string;
  userId: string;
  authProvider: GitProviderName;
  authEndpoint: string;
  authType: GitAuthType;
  expires: string;
}

const logger = getLoggerNew(__modulename);
const OPERATION_TIMEOUT_IN_MILLISECONDS = 5 * 60 * 1000; // 5 minutes
let _hostingUrlConfigCache: Promise<void>;

const gitProviderToAuthPopupWindowSize: Record<GitProviderName, { width: number; height: number }> = {
  [GitProviderName.GitHub]: { width: 500, height: 700 },
  [GitProviderName.GitHubEnterprise]: { width: 500, height: 700 },
  [GitProviderName.GitLab]: { width: 500, height: 700 },
  [GitProviderName.GitLabEnterprise]: { width: 500, height: 700 },
  [GitProviderName.Bitbucket]: { width: 1100, height: 700 },
  [GitProviderName.BitbucketDc]: { width: 1100, height: 700 },
  [GitProviderName.AzureDevops]: { width: 1100, height: 700 },
  [GitProviderName.Testing]: { width: 500, height: 700 },
};

export function useGitHostingAuthorization() {
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const route = useRoute();
  const analytics = useAnalytics();
  const { markStepAsDone } = useGetStartedMenu();

  const saveUserAuthState = (args?) => store.dispatch('database/saveUserAuthState', args);

  const gitHostingsConfig = ref<Record<string, BasicConfig>>({});
  const authorizedGitHostings = ref<Record<string, boolean>>({});

  const workspaceId = computed(() => route.params.workspaceId as string);
  const authType: ComputedRef<GitAuthType> = computed(
    () => store.getters['database/db_getWorkspace'](workspaceId.value)?.auth_type
  );
  function isGitHostingUrlAuthorized(gitHostingKey: string): boolean {
    logger.info(
      `isGitHostingUrlAuthorized: looking for ${gitHostingKey} in authorizedGitHostings ${JSON.stringify(
        Object.keys(authorizedGitHostings.value)
      )}`
    );
    const isAuthorized = !!authorizedGitHostings.value[gitHostingKey];
    logger.info(`isGitHostingUrlAuthorized? ${isAuthorized}`);
    return isAuthorized;
  }

  async function validateGitHostingToken(
    provider: GitProviderName,
    gitHostingUrl: string,
    tenantId?: string
  ): Promise<void> {
    logger.info(
      `validateGitHostingToken for provider: ${provider}, gitHostingUrl: ${gitHostingUrl} and tenantId: ${
        tenantId ?? 'null'
      }`
    );
    const token = await gitProviderUtils.getVerifiedGitHostingToken({
      gitHostingUrl,
      provider,
      tenantId,
      trackAuthorize,
    });
    if (!token) {
      logger.info(`Token for ${provider} is invalid or empty`);
      const gitHostingKey = UrlUtils.getGitHostingKey({ provider, gitUrl: gitHostingUrl, tenantId });
      delete authorizedGitHostings.value[gitHostingKey];
      logger.info(`${gitHostingKey} was removed from authorizedGitHostings`);
    }
  }

  function trackAuthorize(payload: { Reason: string }): void {
    analytics.track(productEvents.DETECTED_INVALID_GITHUB_TOKEN, payload);
  }

  async function getStateForAuthUrl(redirect: string): Promise<string> {
    const state = uuidv4();
    const authData: Record<string, string> = { state: state };
    if (redirect) {
      authData.redirect = redirect;
    }
    await saveUserAuthState({ authData, userId: user.value.uid });

    return state;
  }

  /**
   * Sets all the data we need to authenticate locally in Cookies
   */
  function handleAuthLocallyRequest(gitHostingUrl: string, provider: GitProviderName, authType: GitAuthType): void {
    const expires = new Date(new Date().getTime() + OPERATION_TIMEOUT_IN_MILLISECONDS * 2);
    const localAuthState: LocalAuthState = {
      authName: gitHostingUrl,
      workspaceId: workspaceId.value,
      userId: user.value.uid,
      authProvider: provider,
      // To use as a fallback if the popup is stuck
      authEndpoint: '',
      authType,
      expires: expires.toJSON(),
    };
    sessionStorage.setItem('authState', JSON.stringify(localAuthState));
  }

  const buildAuthUrlForGitHub: buildUrlFuncType = async function ({
    gitHostingUrl,
    shouldAllowAccessToPrivateRepos,
    redirect,
  }) {
    const currentHostingConfig = gitHostingsConfig.value[
      UrlUtils.getGitHostingKey({ provider: GitProviderName.GitHub, gitUrl: gitHostingUrl })
    ] as BasicConfig;
    const scopeName = shouldAllowAccessToPrivateRepos ? 'repo' : 'public_repo';
    const randomState = await getStateForAuthUrl(redirect);
    const stateParams = new URLSearchParams({ state_id: randomState });

    let redirectUrl = '';
    if (!currentHostingConfig.should_authorize_locally) {
      stateParams.set('git_hosting', gitHostingUrl);
      stateParams.set('uid', user.value.uid);
      stateParams.set('auth_type', authType.value);

      redirectUrl = `${AUTH_URL}/githubAuth`;
    } else {
      handleAuthLocallyRequest(gitHostingUrl, GitProviderName.GitHubEnterprise, authType.value);
    }

    const authorizationParams = new URLSearchParams({
      client_id: currentHostingConfig.client_id,
      state: encodeURIComponent(stateParams.toString()),
    });

    if (authType.value === 'oauth_app') {
      authorizationParams.set('scope', scopeName);
    }

    if (redirectUrl) {
      authorizationParams.set('redirect_uri', redirectUrl);
    }

    return `https://${gitHostingUrl}/login/oauth/authorize?${authorizationParams.toString()}`;
  };

  const buildAuthUrlForGitLab: buildUrlFuncType = async function ({
    gitHostingUrl,
    // There is no way to access only public repos
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldAllowAccessToPrivateRepos,
    redirect,
  }) {
    const currentHostingConfig = gitHostingsConfig.value[
      UrlUtils.getGitHostingKey({ provider: GitProviderName.GitLab, gitUrl: gitHostingUrl })
    ] as BasicConfig;
    const randomState = await getStateForAuthUrl(redirect);
    const scope = 'api';

    let redirectUri = '';
    if (!currentHostingConfig.should_authorize_locally) {
      redirectUri = encodeURIComponent(`${AUTH_URL}/gitlabAuth?id=${user.value.uid}&git_hosting=${gitHostingUrl}`);
    } else {
      handleAuthLocallyRequest(gitHostingUrl, GitProviderName.GitLabEnterprise, authType.value);
      redirectUri = encodeURIComponent(`${config.BASE_URL}/localGitProviderAuth`);
    }

    return `https://${gitHostingUrl}/oauth/authorize?response_type=code&client_id=${currentHostingConfig.client_id}&state=${randomState}&scope=${scope}&redirect_uri=${redirectUri}`;
  };

  const buildAuthUrlForBitbucket: buildUrlFuncType = async function ({
    gitHostingUrl,
    // There is no way to access only public repos
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldAllowAccessToPrivateRepos,
    redirect,
  }) {
    const currentHostingConfig = gitHostingsConfig.value[
      UrlUtils.getGitHostingKey({ provider: GitProviderName.Bitbucket, gitUrl: gitHostingUrl })
    ] as BasicConfig;
    const randomState = await getStateForAuthUrl(redirect);
    const redirectUrl = encodeURIComponent(
      `${AUTH_URL}/bitbucketAuth?git_hosting=${gitHostingUrl}&uid=${user.value.uid}`
    );
    return `https://bitbucket.org/site/oauth2/authorize?client_id=${currentHostingConfig.client_id}&response_type=code&state=${randomState}&redirect_uri=${redirectUrl}`;
  };

  const buildAuthUrlForBitbucketDC: buildUrlFuncType = async function ({
    gitHostingUrl,
    // There is no way to access only public repos
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldAllowAccessToPrivateRepos,
    redirect,
  }) {
    const currentHostingConfig = gitHostingsConfig.value[
      UrlUtils.getGitHostingKey({ provider: GitProviderName.BitbucketDc, gitUrl: gitHostingUrl })
    ] as BasicConfig;
    const randomState = await getStateForAuthUrl(redirect);
    const scopeName = 'REPO_WRITE';
    handleAuthLocallyRequest(gitHostingUrl, GitProviderName.BitbucketDc, authType.value);

    const redirectURI = encodeURIComponent(`${config.BASE_URL}/localGitProviderAuth`);

    const port = currentHostingConfig?.port ? `:${currentHostingConfig.port}` : '';
    return `https://${gitHostingUrl}${port}/rest/oauth2/latest/authorize?client_id=${currentHostingConfig.client_id}&scope=${scopeName}&response_type=code&state=${randomState}&redirect_uri=${redirectURI}`;
  };

  function getAuthrizationConfigurationForAzureDevops(
    gitHostingUrl: string,
    tenantId?: string
  ): {
    baseUrl: string;
    scopeName: string;
    responseType: string;
    redirectUrl: string;
  } {
    const isMultiTenantOauth =
      gitHostingUrl !== UrlUtils.providerToGitCloudHostingUrl(GitProviderName.AzureDevops) && !!tenantId; // We are in a multitenant scenario

    if (isMultiTenantOauth) {
      return {
        baseUrl: `https://${gitHostingUrl}/${tenantId}/oauth2/v2.0/authorize`,
        scopeName: '499b84ac-1321-427f-aa17-267ca6975798/.default offline_access',
        responseType: 'code',
        redirectUrl: encodeURIComponent(`${AUTH_URL}/azureAuth?git_hosting=${gitHostingUrl}&tenant_id=${tenantId}`),
      };
    }

    return {
      baseUrl: 'https://app.vssps.visualstudio.com/oauth2/authorize',
      scopeName: 'vso.code_write vso.project',
      responseType: 'Assertion',
      redirectUrl: encodeURIComponent(`${AUTH_URL}/azureAuth`),
    };
  }

  const buildAuthUrlForAzure: buildUrlFuncType = async function ({
    gitHostingUrl,
    tenantId,
    // There is no way to access only public repos
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldAllowAccessToPrivateRepos,
    redirect,
  }) {
    const gitHostingKey = UrlUtils.getGitHostingKey({
      provider: GitProviderName.AzureDevops,
      gitUrl: gitHostingUrl,
      tenantId,
    });
    const currentHostingConfig = gitHostingsConfig.value[gitHostingKey] as BasicConfig;
    const { baseUrl, scopeName, responseType, redirectUrl } = getAuthrizationConfigurationForAzureDevops(
      gitHostingUrl,
      tenantId
    );

    const randomState = await getStateForAuthUrl(redirect);

    return `${baseUrl}?client_id=${currentHostingConfig.client_id}&response_type=${responseType}&state=${randomState}&scope=${scopeName}&redirect_uri=${redirectUrl}`;
  };

  const gitProviderToAuthUrlMap: Record<GitProviderName, buildUrlFuncType> = {
    [GitProviderName.GitHub]: buildAuthUrlForGitHub,
    [GitProviderName.GitHubEnterprise]: buildAuthUrlForGitHub,
    [GitProviderName.GitLab]: buildAuthUrlForGitLab,
    [GitProviderName.GitLabEnterprise]: buildAuthUrlForGitLab,
    [GitProviderName.Bitbucket]: buildAuthUrlForBitbucket,
    [GitProviderName.BitbucketDc]: buildAuthUrlForBitbucketDC,
    [GitProviderName.AzureDevops]: buildAuthUrlForAzure,
    [GitProviderName.Testing]: buildAuthUrlForGitHub,
  };

  async function authorizeIfNeeded({
    gitHostingUrl,
    tenantId,
    provider,
    shouldAllowAccessToPrivateRepos = true,
    redirect = window.location.origin,
    origin,
  }: {
    gitHostingUrl: string;
    tenantId?: string;
    provider: GitProviderName;
    shouldAllowAccessToPrivateRepos?: boolean;
    origin: string;
    redirect?: string;
  }): Promise<void> {
    const gitHostingKey = UrlUtils.getGitHostingKey({ provider, gitUrl: gitHostingUrl, tenantId });
    if (
      isGitHostingUrlAuthorized(gitHostingKey) &&
      (await gitProviderUtils.getVerifiedGitHostingToken({ gitHostingUrl, provider, tenantId, trackAuthorize }))
    ) {
      return;
    }

    logger.debug(`Token for ${gitHostingKey} is invalid or empty`);
    if (!gitHostingsConfig.value[gitHostingKey]) {
      // in case it isn't initialized yet
      await getGitHostingConfig([gitHostingKey]);
    }

    const authUrl = await gitProviderToAuthUrlMap[provider]({
      gitHostingUrl,
      tenantId,
      shouldAllowAccessToPrivateRepos,
      redirect,
    });
    await openGitAuthorizeWithPopup({ popUpUrl: authUrl, gitHostingUrl, tenantId, provider }).then(() => {
      const payload = {
        Permissions: shouldAllowAccessToPrivateRepos ? 'Private' : 'Public',
        Origin: origin,
        'Git Provider': provider,
      };
      analytics.cloudTrack({
        identity: user.value.uid,
        event: productEvents.GITHUB_AUTHORIZATION_SUCCESS,
        payload,
      });
      analytics.track(productEvents.GITHUB_AUTHORIZATION_SUCCESS_MARKETING, payload);
      // Update the get started state
      markStepAsDone(GetStartedStepIds.AUTHORIZE_GITHUB);
    });
  }

  async function openGitAuthorizeWithPopup({
    popUpUrl,
    gitHostingUrl,
    tenantId,
    provider,
  }: {
    popUpUrl: string;
    gitHostingUrl: string;
    tenantId?: string;
    provider: GitProviderName;
  }): Promise<void> {
    logger.debug(`Authorizing Swimm with ${gitHostingUrl}`);
    const providerDisplayName = GitProviderInfo[provider]?.displayName;

    const gitHostingKey = UrlUtils.getGitHostingKey({ provider, gitUrl: gitHostingUrl, tenantId });
    const hasToken = await openAsyncPopup({
      url: popUpUrl,
      width: gitProviderToAuthPopupWindowSize[provider].width,
      height: gitProviderToAuthPopupWindowSize[provider].height,
      displayName: providerDisplayName,
      checkSuccessCB: async () => !!(await gitProviderUtils.getGitHostingToken(gitHostingKey)),
    });

    if (hasToken) {
      authorizedGitHostings.value[gitHostingKey] = true;
      void saveUserData(provider, gitHostingUrl, tenantId);
    }
  }

  async function saveUserData(provider: GitProviderName, gitHostingUrl: string, tenantId?: string): Promise<void> {
    let userGitData: { login: string; id: number };
    try {
      let baseUrl: string;
      switch (provider) {
        case GitProviderName.GitHubEnterprise: {
          baseUrl = `https://${gitHostingUrl}/api/v3`;
          break;
        }
        case GitProviderName.BitbucketDc: {
          baseUrl = `https://${gitHostingUrl}/rest`;
          break;
        }
        case GitProviderName.GitLabEnterprise: {
          baseUrl = `https://${gitHostingUrl}`;
          break;
        }
        case GitProviderName.AzureDevops: {
          if (tenantId) {
            baseUrl = `https://${gitHostingUrl}`;
          } else {
            baseUrl = null;
          }
          break;
        }
        default: {
          baseUrl = null;
        }
      }

      const driverConfig = { ...(baseUrl && { baseUrl, tenantId }), ...(!baseUrl && {}) };
      userGitData = await gitwrapper.getUserData(provider, driverConfig);
      const connectedOrgs = await gitwrapper.getUserOrganizations(provider, driverConfig);

      await gitProviderUtils.setAuthorizedOrgs(
        connectedOrgs,
        UrlUtils.getGitHostingKey({ provider, gitUrl: gitHostingUrl, tenantId })
      );
    } catch (err) {
      logger.error({ err }, `Error getting user data from ${provider}, error: ${err}`);
      return;
    }

    const saveResponse = await setValuesInDoc(
      collectionNames.USERS,
      user.value.uid,
      { [`${provider}_login`]: userGitData.login, [`${provider}_id`]: userGitData.id },
      { merge: true }
    );
    if (saveResponse.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(
        `Provider ${provider}: Error setting user data in DB for user ${user.value.uid}, error: ${saveResponse.errorMessage}`
      );
    } else {
      logger.info(`Provider ${provider}: User data for user ${user.value.uid} saved successfully`);
    }
  }

  async function getGitHostingConfig(gitHostingUrls: string[]): Promise<void> {
    const overrideSecretName = authType.value === 'github_app' ? 'GITHUB_APP_AUTH' : undefined;

    const { data } = await CloudFunctions.fetchAuthConfig({ gitHostingUrls, secretName: overrideSecretName });
    if (data) {
      Object.keys(data).forEach((url) => (gitHostingsConfig.value[url] = data[url]));
    }
  }

  async function fetchGitHostingsConfigData(gitHostingsKeys: string[]): Promise<void> {
    const hostingsWithoutConfig = gitHostingsKeys.filter((hostNameKey) => !gitHostingsConfig.value[hostNameKey]);
    if (!_hostingUrlConfigCache) {
      _hostingUrlConfigCache = getGitHostingConfig(hostingsWithoutConfig);
    }

    await _hostingUrlConfigCache;

    _hostingUrlConfigCache = null;
  }

  async function loadAuthorizedGitHostings(): Promise<void> {
    const authorizedHostnames = await gitProviderUtils.getAllAuthorizedGitHostings();
    for (const hostname of authorizedHostnames) {
      authorizedGitHostings.value[hostname] = true;
    }
  }

  return {
    authorizeIfNeeded,
    isGitHostingUrlAuthorized,
    fetchGitHostingsConfigData,
    loadAuthorizedGitHostings,
    validateGitHostingToken,
    authorizedGitHostings,
    getGitHostingConfig,
  };
}
