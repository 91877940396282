<template>
  <div v-if="!loading" class="doc-toc" data-testid="doc-toc">
    <template v-if="root && root.children.length">
      <TocNode
        v-for="(heading, index) of root.children"
        ref="tocNodes"
        :key="index"
        :heading="heading"
        :top-level="topLevel"
        :doc="doc"
        :test-path="`${index}`"
        :visible-id="visibleId"
        :is-shown="isShown"
        :last-smooth-scroll-time="Math.max(lastTitleScrollTime ?? 0, lastHeadingScrollTime ?? 0)"
        @heading-clicked="headingClicked"
      />
    </template>
    <BaseLayoutGap v-else size="xsmall" direction="row" alignment="left">
      <BaseIcon name="list-view" />
      <BaseProse size="small" class="empty-toc" data-testid="doc-toc-empty"> Headings will be listed here </BaseProse>
    </BaseLayoutGap>
  </div>
</template>

<script lang="ts">
import TocNode from '@/components/DocToc/TocNode.vue';
import { type TOCTree, type TOCTreeRoot, buildDocToc } from '@/components/DocToc/doc-toc-utils';
import { getLoggerNew, productEvents } from '@swimm/shared';
import type { PropType } from 'vue';
import { BaseIcon, BaseLayoutGap, BaseProse } from '@swimm/reefui';
import { useScroll } from '@/composables/scroll';

const logger = getLoggerNew(__modulename);

export default {
  components: {
    TocNode,
    BaseIcon,
    BaseProse,
    BaseLayoutGap,
  },
  props: {
    doc: { type: Object, required: true },
    workspaceId: { type: String, required: true },
    repoId: { type: String, required: true },
    headings: { type: Object as PropType<NodeListOf<Element>>, default: null },
    isShown: { type: Boolean, default: true },
    lastTitleScrollTime: { type: Number, required: false },
  },
  setup() {
    const scroll = useScroll();
    return { scroll };
  },
  data() {
    return {
      loading: true,
      root: null as TOCTreeRoot | null,
      visibleId: '',
      allHeadings: [] as TOCTree[],
      intersectionObserver: null as IntersectionObserver | null,
      lastHeadingScrollTime: null as number | null,
    };
  },
  emits: ['track-analytic', 'heading-clicked'],
  computed: {
    topLevel() {
      if (this.root?.children.length) {
        return this.root?.children.map((c) => c.level).reduce((l1, l2) => Math.min(l1, l2), 1000);
      }
      return 0;
    },
  },
  watch: {
    headings: {
      handler(value) {
        if (value) {
          this.buildToc();
        }
      },
      immediate: true,
    },
    lastTitleScrollTime() {
      // When users scrolls to the title, we want to scroll to the first heading too.
      setTimeout(
        () => {
          const heading = (this.$refs.tocNodes as InstanceType<typeof TocNode>[])[0]?.$el;
          // If we're already scrolled into view, don't scroll again
          if (!heading || this.scroll.isScrolledIntoView(heading)) {
            return;
          }
          heading.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        // Wait for the title scroll to finish.
        1000
      );
    },
  },
  beforeUnmount() {
    this.stopObserve();
  },
  methods: {
    headingClicked(heading: TOCTree) {
      this.$emit('track-analytic', productEvents.CLICKED_DOC_TOC_ITEM, {
        'Workspace ID': this.workspaceId,
        'Repo ID': this.repoId,
        'Document ID': this.doc.id,
        Context: 'View Doc',
        'Item Type': `H${heading.level}`,
      });
      heading.$element.scrollIntoView({
        behavior: 'smooth',
      });
      this.lastHeadingScrollTime = Date.now();
      this.$emit('heading-clicked');
    },
    buildToc() {
      try {
        const { root, allHeadings } = buildDocToc(this.headings);
        this.root = root;
        this.allHeadings = allHeadings;
        this.visibleId = '';
        this.startObserve();
      } catch (err) {
        logger.error({ err }, `Failed to build toc ${err}`);
      }
      this.loading = false;
    },
    startObserve() {
      this.stopObserve();
      if (this.allHeadings.length === 0) {
        return;
      }
      const allHeadingsByElement = new Map<Element, TOCTree>();
      for (const heading of this.allHeadings) {
        allHeadingsByElement.set(heading.$element, heading);
      }
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            const heading = allHeadingsByElement.get(entry.target);
            if (heading) {
              heading.ratio = entry.intersectionRatio;
            }
          }
          // Assume the current visible header is the
          // first one with ratio > 0.9
          for (const heading of this.allHeadings) {
            if ((heading.ratio ?? 0) > 0.9) {
              this.visibleId = heading.id;
              return;
            }
          }
        },
        {
          threshold: [0, 0.9, 0.92, 0.95, 1],
        }
      );
      for (const heading of this.allHeadings) {
        heading.ratio = 0;
        this.intersectionObserver.observe(heading.$element);
      }
    },
    stopObserve() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        this.intersectionObserver = null;
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.doc-toc {
  display: flex;
  flex-direction: column;
  gap: var(--space-xsmall);

  .empty-toc {
    color: var(--text-color-secondary);
    flex-direction: row;
    align-items: center;
    gap: var(--space-xsmall);
    user-select: none;
  }
}
</style>
