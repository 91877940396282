<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="resource">
    <div class="resource__header">
      <slot name="header" />
    </div>
    <div class="resource__body">
      <slot />
    </div>
    <div v-if="slots.footer" class="resource__footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useSlots } from 'vue';

const slots = useSlots();
</script>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.resource {
  $self: &;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: var(--space-large);
  width: 100%;

  &__footer {
    margin-top: 0;
    margin-bottom: var(--space-lg);
  }

  &__header,
  &__footer {
    margin-left: var(--narrow-content-width-left-padding);
    margin-right: var(--narrow-content-width-right-padding);
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>
