<template>
  <div v-if="isLoading">
    <Loader class="view-loader" />
  </div>
  <SwText v-else variant="body-L" class="error-text">Failed Authenticating with JumpCloud</SwText>
</template>
<script>
import { config } from '@swimm/shared';
import { authenticateWithJumpCloud } from '@/modules/core/composables/sso';

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    const codeVerifier = sessionStorage.getItem('swimm_code_verifier');
    const clientID = sessionStorage.getItem('swimm_client_id');

    const isSuccess = await authenticateWithJumpCloud({
      grant_type: 'authorization_code',
      code: this.$route.query.code,
      redirect_uri: `${config.BASE_URL}/setOIDCToken`,
      client_id: clientID,
      code_verifier: codeVerifier,
    });

    if (isSuccess) {
      const myWindow = window.open('', '_self');
      setTimeout(function () {
        myWindow.close();
      }, 1000);
    } else {
      this.isLoading = false;
    }
  },
};
</script>
<style scoped>
.error-text {
  margin-top: var(--space-large);
  text-align: center;
}
</style>
