<template>
  <BaseBackgroundLayout :theme="BackgroundTheme.BLURRED_APP">
    <BaseCard v-if="!loading" class="card-container">
      <div>
        <SwText variant="headline2">Set up your account</SwText>
      </div>
      <form
        data-testid="create-workspace-form"
        class="create-workspace-fields"
        @keydown.enter.prevent="handleNextClick"
        @submit.prevent="handleNextClick"
      >
        <TextField
          v-model.trim="workspaceName"
          data-testid="company-name-field"
          label="Company name"
          placeholder="Acme Inc."
          class="input company-name-input"
          :maxlength="100"
          :error="workspaceNameError"
          :disabled="loadingWorkspaceJoin"
          required
          @update:model-value="onWorkspaceNameChange"
        />
        <SelectableButtons
          class="selectable-buttons"
          @update-selection="(amount) => (this.numberOfDevelopers = amount)"
          :options="DEV_AMOUNT_OPTIONS"
          :selected-option="numberOfDevelopers"
          :label="DEV_AMOUNT_LABEL"
          :disabled="loadingWorkspaceJoin"
          v-if="!isDevAmountPrefilled"
        />
        <div>
          <BaseSelect
            class="input"
            label="Git hosting platform"
            :options="GIT_HOSTING_OPTIONS"
            :model-value="gitHosting"
            :searchable="false"
            :error="gitHostingError"
            :disabled="loadingWorkspaceJoin || isGitProviderPrefilled"
            append-to-body
            required
            @input="onGitHostingChange"
          />
          <Ribbon
            v-if="isSupportedRequiresHelp && gitHosting !== 'GitHub Enterprise Server'"
            hide-icon
            mode="web"
            class="enterprise-banner"
            >{{ gitHosting }} requires additional setup with the help of the Swimm team. You can explore Swimm now and
            talk to an expert when you are ready to set up your git hosting.</Ribbon
          >
        </div>
        <Action
          data-testid="set-up-account-button"
          class="create-button"
          :disabled="disableNext"
          :loading="loadingWorkspaceJoin"
          >Set up account<Icon name="arrow-next-up" no-padding class="arrow-icon"
        /></Action>
      </form>
      <LogoutFooter
        origin="Set up your workspace"
        email-prefix-string="Set up account for"
        :user-email="user.email"
        try-different-prefix-string="If you believe you were invited to a workspace"
      />
    </BaseCard>
  </BaseBackgroundLayout>
</template>

<script>
import { useInitData } from '@/common/composables/initData';
import BaseBackgroundLayout from '@/modules/onboarding/layouts/BaseBackgroundLayout.vue';
import BaseCard from '@/modules/core/components/BaseCard.vue';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import { BaseSelect } from '@swimm/ui';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { MOBILE_MAX_WIDTH, PageRoutesNames } from '@/common/consts';
import LogoutFooter from '@/common/pages/JoinWorkspace/LogoutFooter.vue';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import {
  updateSalesforceWorkspaceCreation,
  updateWorkspaceUserInSalesforce,
} from '@/common/utils/salesforce/salesforce';
import {
  GIT_HOSTING_OPTIONS,
  GitProviderName,
  HOSTING_NAME_TO_PROVIDER,
  Licenses,
  SELF_SERVED_SUPPORTED_GIT_HOSTINGS,
  SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP,
  UserOnboardingFields,
  billingPlanTypes,
  config,
  eventLogger,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
  pageEvents,
  productEvents,
  state,
} from '@swimm/shared';
import swal from 'sweetalert';
import { setUserFieldInDB } from '@/common/utils/user-utils';
import { mapActions } from 'vuex';
import { localStorageKeys } from '@/common/consts';
import LocalStorage from '@/local-storage';
import { BackgroundTheme, ONBOARDING_STEP_NAMES } from '@/modules/onboarding/consts';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useStigg } from '@/common/composables/useStigg';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { measurePerformance } from '@/common/utils/sentry-measurements';
import SelectableButtons from '@/common/components/atoms/SelectableButtons.vue';
import { Action, SwText, TextField } from '@swimm/ui';
import { inRange } from 'lodash-es';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';

const logger = getLoggerNew(__modulename);

const ErrorMessages = {
  GIT_HOSTING_REQUIRED: 'Git hosting platform is required',
  COMPANY_NAME_REQUIRED: 'Company name is required',
};

export default {
  components: {
    BaseBackgroundLayout,
    BaseCard,
    LogoutFooter,
    Action,
    SwText,
    TextField,
    BaseSelect,
    SelectableButtons,
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const { setFirstWorkspace } = useUserConfigStore();
    const analytics = useAnalytics();
    const { logEvent } = useSwimmEventLogs();
    const { setWorkspaceData } = useInitData();
    const { initStiggForCustomer } = useStigg();
    const { isProviderAuthorized } = useGitAuthorizationStore();
    const route = useRoute();

    const DEV_AMOUNT_OPTIONS = ['1-49', '50-74', '75-199', '200+'];
    const DEV_AMOUNT_LABEL = 'How many developers do you have in your company?';

    const isDevAmountPrefilled = ref(false);

    function devAmountOptionToUse(num) {
      switch (true) {
        case inRange(num, 1, 49): {
          return DEV_AMOUNT_OPTIONS[0];
        }
        case inRange(num, 50, 74): {
          return DEV_AMOUNT_OPTIONS[1];
        }
        case inRange(num, 75, 199): {
          return DEV_AMOUNT_OPTIONS[2];
        }
        case inRange(num, 200): {
          return DEV_AMOUNT_OPTIONS[3];
        }
        default: {
          return null;
        }
      }
    }

    async function fetchOrgData() {
      if (route.query?.source !== config.GH_MARKETPLACE_INDICATOR) {
        return;
      }

      // This is for authorization with GitHub marketplace
      if (!isProviderAuthorized({ provider: GitProviderName.GitHub })) {
        return;
      }

      const orgData = await gitwrapper.getOrganizationData(GitProviderName.GitHub);
      analytics.track(productEvents.SHOWN_MARKETPLACE_WORKSPACE_SETUP, {
        'Organization Name': orgData?.name ? orgData.name : null,
        'Fetched Dev Count': orgData?.numOfDevelopers ? orgData.numOfDevelopers : null,
      });
      const devAmountRange = devAmountOptionToUse(orgData.numOfDevelopers);
      if (devAmountRange) {
        isDevAmountPrefilled.value = true;
      }
      return { name: orgData.name, devAmountRange };
    }

    return {
      GIT_HOSTING_OPTIONS,
      user,
      ONBOARDING_STEP_NAMES,
      analytics,
      logEvent,
      setWorkspaceData,
      initStiggForCustomer,
      setFirstWorkspace,
      DEV_AMOUNT_OPTIONS,
      DEV_AMOUNT_LABEL,
      BackgroundTheme,
      fetchOrgData,
      isDevAmountPrefilled,
    };
  },
  data() {
    return {
      loading: true,
      loadingWorkspaceJoin: false,
      workspaceName: '',
      workspaceNameError: '',
      gitHosting: SELF_SERVED_SUPPORTED_GIT_HOSTINGS[0],
      numberOfDevelopers: '',
      isGitProviderPrefilled: false,
    };
  },
  computed: {
    disableNext() {
      return !this.workspaceName || !!this.workspaceNameError || !this.numberOfDevelopers;
    },
    isSelectedGitHostingSupported() {
      return [...SELF_SERVED_SUPPORTED_GIT_HOSTINGS, ...SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP].includes(this.gitHosting);
    },
    isSupportedRequiresHelp() {
      return SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP.includes(this.gitHosting);
    },
    gitHostingError() {
      const supportedProvidersString = SELF_SERVED_SUPPORTED_GIT_HOSTINGS.join(' or ');
      const errorMsg =
        (this.gitHosting === 'Other'
          ? `Sorry, we only support the listed Git hosting platforms at the moment.`
          : `Sorry, we don't support ${this.gitHosting} at the moment.`) +
        ` Please check back later or try using our product with ${supportedProvidersString}`;
      if (this.isSelectedGitHostingSupported) {
        return '';
      } else {
        return errorMsg;
      }
    },
  },
  async created() {
    this.analytics.pageVisit(pageEvents.VIEW_SET_UP_YOUR_WORKSPACE, {
      Type: 'Page',
    });
    await state.set({ key: 'should_show_welcome_modal', value: true });
    LocalStorage.set(localStorageKeys.SHOULD_OPEN_GET_STARTED, true);

    await this.setOrgData();

    this.loading = false;
  },
  methods: {
    ...mapActions('database', ['saveWorkspace']),
    async setOrgData() {
      const orgData = await this.fetchOrgData();
      if (orgData) {
        this.workspaceName = orgData.name;
        this.isGitProviderPrefilled = true;
        if (orgData.devAmountRange) {
          this.numberOfDevelopers = orgData.devAmountRange;
        }
      }
    },
    onWorkspaceNameChange() {
      this.workspaceNameError = '';
    },
    onGitHostingChange(value) {
      this.gitHosting = value;
      this.analytics.cloudTrack({
        identity: this.user?.uid || 'Unknown',
        event: productEvents.GIT_HOSTING_SELECTION,
        payload: {
          platform: value,
        },
      });
    },
    async handleNextClick() {
      if (!this.isSelectedGitHostingSupported) {
        let userId = '';
        const traits = { 'Git Hosting Provider': this.gitHosting, 'Creator User ID': '', 'Creator Email': '' };
        if (this.user) {
          userId = this.user.uid;
          traits['Creator User ID'] = userId;
          traits['Creator Email'] = this.user.email ?? '';
        }
        this.analytics.cloudTrack({
          identity: userId,
          event: productEvents.GIT_HOSTING_NOT_SUPPORTED,
          payload: traits,
        });
      }
      await this.onCreateWorkspace();
    },
    async onCreateWorkspace() {
      if (!this.workspaceName) {
        this.workspaceNameError = ErrorMessages.COMPANY_NAME_REQUIRED;
      }

      if (this.workspaceNameError) {
        return;
      }

      await state.set({ key: 'should_show_version_modal', value: false });

      const workspaceId = await this.createWorkspace();
      await this.setWorkspaceData(workspaceId);
      this.loadingWorkspaceJoin = false;
      this.$router.push({
        name: PageRoutesNames.ACCOUNT_READY,
        params: { workspaceId },
        query: {
          ...this.$route.query,
          provider: this.gitHosting,
          isEnterprise: this.isSupportedRequiresHelp,
        },
      });
    },
    async createWorkspace() {
      const provider = HOSTING_NAME_TO_PROVIDER[this.gitHosting];
      logger.info(`Creating a new workspace "${this.workspaceName}" on provider ${provider}`);
      this.loadingWorkspaceJoin = true;
      const isMobile = screen.width <= MOBILE_MAX_WIDTH;
      const workspaceSettings = !gitProviderUtils.isProviderCloud(provider)
        ? { is_workspace_local_mode: true, disable_gen_ai: true }
        : {};
      let workspaceId = null;

      const workspace = {
        name: this.workspaceName,
        provider,
        auth_type: 'oauth_app',
        description: '',
        ...(this.isSupportedRequiresHelp ? { pending_manual_setup: true } : {}),
        settings: workspaceSettings,
      };

      try {
        const saveWorkspace = this.saveWorkspace;
        const user = this.user;
        await measurePerformance({ name: 'createWorkspace-save' }, async () => {
          workspaceId = await saveWorkspace({ resource: workspace, user });
        });

        await measurePerformance({ name: 'createWorkspace-addToPlanManager' }, async () => {
          await CloudFunctions.addWorkspaceToPlanManager({
            workspaceId,
            workspaceName: this.workspaceName,
            license: Licenses.FREE,
          });
        });

        const initStiggForCustomer = this.initStiggForCustomer;
        await measurePerformance({ name: 'createWorkspace-stiggInit' }, async () => {
          await initStiggForCustomer(workspaceId);
        });

        if (isMobile) {
          // In some mobile devices and browser this is failed to be called once moving to swimm.io website from the mobile page
          await this.reportWorkspaceCreated(workspaceId, workspace.name);
        } else {
          this.reportWorkspaceCreated(workspaceId, workspace.name);
        }
      } catch (err) {
        logger.error({ err }, `could not save workspace: ${err}`);
        await swal({ title: 'Failed to create workspace.', content: SWAL_CONTACT_US_CONTENT() });
      }
      if (workspaceId) {
        try {
          const uid = this.user.id;
          const setFirstWorkspace = this.setFirstWorkspace;
          await measurePerformance({ name: 'createWorkspace-setUser' }, async () => {
            await setUserFieldInDB(uid, UserOnboardingFields.FIRST_WORKSPACE, workspaceId);
            await setFirstWorkspace({ workspaceId });
          });
        } catch (err) {
          logger.error({ err }, `could not save user.firstWorkspace field to user: ${err}`, {
            service: 'join-workspace',
          });
        }
      }
      return workspaceId;
    },
    async reportWorkspaceCreated(workspaceId, workspaceName) {
      try {
        const analytics = this.analytics;
        const email = this.user.email;
        const developersAmount = this.numberOfDevelopers;
        const gitHosting = this.gitHosting;
        await measurePerformance({ name: 'createWorkspace-hubspotReport' }, async () => {
          await analytics.reportHubspotCreateWorkspace({
            email,
            company: workspaceName,
            gitHosting: gitHosting,
            numEmployees: developersAmount,
          });
        });

        await measurePerformance({ name: 'createWorkspace-setHostedDomainField' }, async () => {
          await CloudFunctions.setHostedDomainField({ workspaceId });
        });
        const logEvent = this.logEvent;
        await measurePerformance({ name: 'createWorkspace-logEvent' }, async () => {
          logEvent(eventLogger.SWIMM_EVENTS.WORKSPACE_CREATED, {
            srcId: workspaceId,
            srcName: workspaceName,
            workspaceId,
            workspaceName,
          });
        });

        const userId = this.user.uid;
        await measurePerformance({ name: 'createWorkspace-userIdentify' }, async () => {
          analytics.userIdentify(userId, {
            email: email,
            groups: { Workspace: workspaceId },
            'Last Login Date': new Date().toISOString(),
          });
        });

        const traits = {
          Name: workspaceName,
          Plan: billingPlanTypes.FREE,
          'Creator User ID': this.user.uid,
          'Creator Email': this.user.email,
          'Creation Date': new Date().toISOString(),
          'Git Hosting Provider': this.gitHosting,
        };
        await measurePerformance({ name: 'createWorkspace-cloudWorkspaceGroup' }, async () => {
          await analytics.cloudWorkspaceGroup({
            workspaceId,
            traits,
            userId,
          });
        });

        await measurePerformance({ name: 'createWorkspace-workspaceChange' }, async () => {
          analytics.workspaceChange({ workspaceId, workspaceName });
        });

        const nickname = this.user.nickname;
        const fullPath = this.$route.fullPath;
        await measurePerformance({ name: 'createWorkspace-cloudTrack' }, async () => {
          const payload = {
            Origin: 'Join workspace page',
            'Origin URL': fullPath,
            Context: 'Sign-Up',
            'Number of Developers': developersAmount ?? '',
            'Git Provider': gitHosting,
            'Workspace ID': workspaceId,
            'Workspace Name': workspaceName,
            'Creator Email': email,
            'Creator Name': nickname,
          };
          await analytics.cloudTrack({
            identity: userId,
            event: productEvents.WORKSPACE_CREATED,
            payload,
          });
          analytics.track(productEvents.WORKSPACE_CREATED_MARKETING, payload);
        });

        await measurePerformance({ name: 'createWorkspace-salesforce' }, async () => {
          await updateSalesforceWorkspaceCreation({
            name: workspaceName,
            id: workspaceId,
            gitHosting: gitHosting,
            companyName: workspaceName,
          });
        });

        await measurePerformance({ name: 'createWorkspace-salesforce-workspace-user' }, async () => {
          await updateWorkspaceUserInSalesforce({ workspaceId: workspaceId, uid: userId });
        });

        logger.debug(`Finished reporting workspace creation, uid: ${this.user.uid}, workspaceId: ${workspaceId}`);
      } catch (err) {
        logger.error({ err }, `Failed to report workspace creation: ${err}`);
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.card-container {
  margin: auto;
  gap: var(--space-md);

  .subtitle {
    color: var(--text-color-secondary);
  }

  .create-workspace-fields {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);

    .selectable-buttons {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .enterprise-banner {
    font-size: var(--body-XS);
    text-align: left;
    padding: 12px var(--space-sm);
    margin-top: var(--space-xs);
  }

  .create-button {
    display: flex;
    align-items: center;
    gap: var(--space-base);

    .arrow-icon {
      transform: rotate(90deg);
    }
  }
}
</style>
