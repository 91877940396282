<script setup lang="ts">
import { uniqBy } from 'lodash-es';
import 'vue-select/dist/vue-select.css';
import VueSelect from 'vue-select';
import Fuse from 'fuse.js';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import BaseIcon, { type IconsType } from '../BaseIcon/BaseIcon.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseLoading from '../BaseLoading/BaseLoading.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseDropdownOption from './BaseDropdownOption.vue';
import { v4 as uuidv4 } from 'uuid';

export type OptionType = {
  id: number | string;
  icon?: IconsType;
  name: string;
  label?: string;
  link?: string;
  metadata?: { icon: IconsType; count: number; tooltip: string };
  group?: string; // Only added for the first item of the group
};

const props = withDefaults(
  defineProps<{
    /**
     * The options to display in the dropdown.
     * They can either be passed as a flat array or grouped by key.
     * Grouped options will be displayed under their key as a title
     */
    options: { [key: string]: OptionType[] } | OptionType[];

    /**
     * The title of the dropdown.
     */
    title?: string;

    /**
     * Allow the uer to trigger a new options fetch
     */
    refreshResults?: boolean;
    /**
     * Placeholder text for the dropdown search box.
     */
    placeholder?: string;

    /**
     * Allows indicating async data fetching - shows a spinner while fetching options.
     */
    fetching?: boolean;

    /**
     * Customize the message displayed when no options match the user's search.
     */
    noOptionsMessage?: string;

    /**
     * Customize the message displayed while fetching. If not provided, the dropdown will be hidden while fetching.
     */
    fetchingMessage?: string;

    /**
     * Show a footer action in the dropdown list.
     * Displayed when the user is searching and no options are found.
     * emits a "search" event when clicked
     */
    footer?: boolean;

    /**
     * custom logic to decide on when the list-footer should appear
     * params: showFooter, query, searching, filteredOptions
     * showFooter: as appears in the footer prop
     * query: the search string entered by the user
     * searching: are options currently being filtered through
     * filteredOptions: the options that match the search string
     */
    displayFooter?: (showFooter: boolean, query: string, searching: boolean, filteredOptions: OptionType[]) => boolean;

    /**
     * Option id to select by default.
     */
    defaultOptionSelected?: number | string;

    /**
     * Whether to focus the dropdown on mount.
     */
    focusOnMount?: boolean;

    /**
     * Disable dropdown
     */
    disabled?: boolean;
  }>(),
  {
    refreshResults: false,
    placeholder: 'Select an option',
    fetching: false,
    noOptionsMessage: 'No matching option found',
    fetchingMessage: undefined,
    footer: false,
    displayFooter: (showFooter: boolean, query: string, searching: boolean, filteredOptions: OptionType[]) =>
      showFooter && !!query && (!searching || !filteredOptions.length),
    defaultOptionSelected: undefined,
    title: undefined,
  }
);

const emit = defineEmits<{
  search: [string];
  selected: [OptionType];
  refresh: [void];
  open: [void];
  close: [void];
  query: [string];
  'filtered-options': [OptionType[]];
}>();

const selectedOption = ref<OptionType>();

const normalizedOptions = computed<OptionType[]>(() => {
  if (Array.isArray(props.options)) {
    return props.options;
  }

  return Object.entries(props.options).reduce((acc, [key, value]) => {
    acc.push(...value.map((option, index) => ({ ...option, group: index === 0 ? key : undefined })));
    return acc;
  }, [] as OptionType[]);
});

watch(
  () => [props.defaultOptionSelected, normalizedOptions.value],
  ([value, opts]) => {
    if (value && !selectedOption.value && (opts as OptionType[]).length > 0) {
      selectedOption.value = normalizedOptions.value.find((option) => option.id === value);
    }
  },
  { immediate: true }
);

const nameFuseOptions: Fuse.IFuseOptions<OptionType> = {
  keys: ['name', 'link'],
  shouldSort: true,
  isCaseSensitive: false,
  threshold: 0.4,
};

const idFuseOptions: Fuse.IFuseOptions<OptionType> = {
  keys: ['id'],
  shouldSort: true,
  isCaseSensitive: true,
  threshold: 0.0,
};

function filter(options: OptionType[], query: string) {
  if (!query.length) {
    return options;
  }
  const fuse = new Fuse<OptionType>(options, nameFuseOptions);
  const nameOptions = fuse.search(query).map(({ item }) => item);
  const idOptions = new Fuse<OptionType>(options, idFuseOptions).search(query).map(({ item }) => item);
  const filteredOptions = uniqBy([...idOptions, ...nameOptions], 'id');
  emit('filtered-options', filteredOptions);
  return filteredOptions;
}

// Implementation note: Unfortunately, vue-select offers no 'official' way to focus the input or the dropdown, nor does
// it directly expose the input element.
// It does provide the 'inputId' field, which allows us to assign a unique ID to the input element and therefore find it
// using getElementById.
// Not the prettiest way to achieve this but I think it is the only reasonable way (and since 'inputId' is a rather
// specific property, I think it's safe to rely on the fact that finding an element by that Id will continue to work
// in the future).
const dropdownInputId = `dropdown-input-id-${uuidv4()}`;

onMounted(() => {
  if (!props.focusOnMount) {
    return;
  }
  nextTick(() => {
    document.getElementById(dropdownInputId)?.focus();
  });
});

// We override dropdownShouldOpen so that we can show the dropdown while loading, to show the fetchingMessage.
const dropdownShouldOpen = ({
  noDrop,
  open,
  mutableLoading,
}: {
  noDrop: boolean;
  open: boolean;
  mutableLoading: boolean;
}) => {
  return noDrop ? false : open && (props.fetchingMessage || !mutableLoading);
};
</script>

<template>
  <div class="dropdown">
    <VueSelect
      v-model="selectedOption"
      class="vs-styler"
      append-to-body
      :options="normalizedOptions"
      :get-option-label="(option: OptionType) => option.name"
      :filter="filter"
      :dropdown-should-open="dropdownShouldOpen"
      :placeholder="placeholder"
      :loading="fetching"
      :input-id="dropdownInputId"
      :clearable="false"
      :disabled="disabled"
      @option:selected="emit('selected', $event)"
      @open="emit('open')"
      @close="emit('close')"
      @search="emit('query', $event)"
    >
      <template #search="{ attributes, events }">
        <BaseIcon v-if="!selectedOption" name="search" class="vs__actions" />
        <input class="vs__search" v-bind="attributes" data-testid="dropdown-search-input" v-on="events" />
      </template>
      <template #spinner="{ loading }">
        <BaseLoading v-if="loading" variant="secondary" size="small" class="vs__spinner" />
      </template>
      <template #no-options="{ loading }">
        <li class="vs__dropdown-option" data-testid="dropdown-no-options">
          <BaseLayoutGap class="no-options">
            <BaseProse
              ><template v-if="!loading">{{ noOptionsMessage }}</template
              ><template v-else>{{ fetchingMessage }}</template></BaseProse
            >
          </BaseLayoutGap>
        </li>
      </template>
      <template #selected-option="{ name, id, icon, label }">
        <BaseLayoutGap direction="row" alignment="left" class="vs__selected-options__selected-option">
          <BaseIcon v-if="icon != null" :name="icon" />
          <BaseProse ellipsis>{{ label ? label : `${name} [#${id}]` }}</BaseProse>
        </BaseLayoutGap>
      </template>
      <template #open-indicator="{ attributes }">
        <!-- We remove the 'label' here since attributes contains role="presentation" and that doesn't work with
             'aria-label' -->
        <BaseIcon name="arrow-down" v-bind="attributes" label="" />
      </template>
      <template #list-header>
        <li v-if="title || refreshResults" class="vs__dropdown-option">
          <BaseLayoutGap :alignment="title ? 'stretch' : 'right'">
            <BaseLayoutGap v-if="title" alignment="left">
              <BaseHeading :level="4">{{ title }}</BaseHeading>
            </BaseLayoutGap>
            <BaseLayoutGap v-if="refreshResults" alignment="right">
              <BaseIcon name="refresh" @click="emit('refresh')" />
            </BaseLayoutGap>
          </BaseLayoutGap>
        </li>
      </template>
      <template #option="{ id, icon, name, label, link, metadata, group }">
        <BaseDropdownOption
          :id="id"
          :name="name"
          :label="label"
          :link="link"
          :icon="icon"
          :metadata="metadata"
          :group="group"
        />
      </template>
      <template #list-footer="{ search, searching, filteredOptions }">
        <li v-if="displayFooter(footer, search, searching, filteredOptions)" class="vs__dropdown-option footer-action">
          <BaseButton variant="info" fill-width class="footer-action__button" @click="emit('search', search)">
            <div class="footer-action__button-text" data-testid="footer-search-button">
              Look for <span class="footer-action__button-search">{{ search }}</span>
            </div>
          </BaseButton>
        </li>
      </template>
    </VueSelect>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dropdown {
  $self: &;

  @include defaults;

  width: 100%;

  .v-select {
    min-width: 300px;
    width: inherit;

    :deep(.vs__actions) {
      margin-left: var(--space-xsmall);
      cursor: pointer;
    }

    :deep(.vs__dropdown-toggle) {
      justify-content: space-between;
    }

    :deep(.vs__search::placeholder) {
      color: var(--color-text-disabled);
    }

    :deep(.vs__selected) {
      min-width: 0;
      position: relative;
    }

    :deep(.vs__selected-options) {
      min-width: 0;
      flex-wrap: nowrap;

      .vs__actions {
        cursor: unset; // we do not want the search icon to receive a pointer cursor
      }
    }

    .vs__selected-options__selected-option {
      min-width: 0;
    }

    :deep(.vs__spinner) {
      width: inherit;
      height: inherit;
    }
  }

  :deep(.no-options) {
    width: 100%;
  }

  .footer-action {
    &__button {
      justify-content: start;
    }

    &__button-text {
      font-family: var(--font-family-primary);
      color: var(--color-text-secondary);
      font-weight: var(--font-weight-regular);
    }

    &__button-search {
      font-family: var(--font-family-primary);
      color: var(--color-text-default);
      font-weight: var(--font-weight-bold);
    }
  }
}
</style>
