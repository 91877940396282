/* tslint:disable */
/* eslint-disable */
/**
 * Swimm\'s Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.43.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
export const AddEnterpriseRequestAuthTypeEnum = {
    OauthApp: 'oauth_app',
    GithubApp: 'github_app'
};
export const AddRepoErrorReasonEnum = {
    ReachedReposLimit: 'ReachedReposLimit',
    RepoInOtherWorkspace: 'RepoInOtherWorkspace',
    AlreadyInWorkspace: 'AlreadyInWorkspace'
};
export const CanAddRepoResultReasonEnum = {
    ReachedReposLimit: 'ReachedReposLimit',
    RepoInOtherWorkspace: 'RepoInOtherWorkspace',
    AlreadyInWorkspace: 'AlreadyInWorkspace'
};
/**
 * AskSwimmBackendApi - axios parameter creator
 * @export
 */
export const AskSwimmBackendApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceEndWorkspacesWorkspaceIdPost: (workspaceId, allowanceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceEndWorkspacesWorkspaceIdPost', 'workspaceId', workspaceId);
            // verify required parameter 'allowanceRequest' is not null or undefined
            assertParamExists('apiAllowanceEndWorkspacesWorkspaceIdPost', 'allowanceRequest', allowanceRequest);
            const localVarPath = `/api/allowance/end/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(allowanceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceStartWorkspacesWorkspaceIdPost: (workspaceId, allowanceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceStartWorkspacesWorkspaceIdPost', 'workspaceId', workspaceId);
            // verify required parameter 'allowanceRequest' is not null or undefined
            assertParamExists('apiAllowanceStartWorkspacesWorkspaceIdPost', 'allowanceRequest', allowanceRequest);
            const localVarPath = `/api/allowance/start/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(allowanceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Increment feature usage
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {AllowanceRequest1} allowanceRequest1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementCap: (workspaceId, featureId, usageCount, allowanceRequest1, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('incrementCap', 'workspaceId', workspaceId);
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('incrementCap', 'featureId', featureId);
            // verify required parameter 'usageCount' is not null or undefined
            assertParamExists('incrementCap', 'usageCount', usageCount);
            // verify required parameter 'allowanceRequest1' is not null or undefined
            assertParamExists('incrementCap', 'allowanceRequest1', allowanceRequest1);
            const localVarPath = `/api/allowance/increment/workspaces/{workspaceId}/features/{featureId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)))
                .replace(`{${"usageCount"}}`, encodeURIComponent(String(usageCount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(allowanceRequest1, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AskSwimmBackendApi - functional programming interface
 * @export
 */
export const AskSwimmBackendApiFp = function (configuration) {
    const localVarAxiosParamCreator = AskSwimmBackendApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AskSwimmBackendApi.apiAllowanceEndWorkspacesWorkspaceIdPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AskSwimmBackendApi.apiAllowanceStartWorkspacesWorkspaceIdPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Increment feature usage
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {AllowanceRequest1} allowanceRequest1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AskSwimmBackendApi.incrementCap']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * AskSwimmBackendApi - factory interface
 * @export
 */
export const AskSwimmBackendApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AskSwimmBackendApiFp(configuration);
    return {
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            return localVarFp.apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Increment AskSwimm feature usage
         * @param {string} workspaceId The workspace ID
         * @param {AllowanceRequest} allowanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
            return localVarFp.apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Increment feature usage
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {AllowanceRequest1} allowanceRequest1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options) {
            return localVarFp.incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AskSwimmBackendApi - object-oriented interface
 * @export
 * @class AskSwimmBackendApi
 * @extends {BaseAPI}
 */
export class AskSwimmBackendApi extends BaseAPI {
    /**
     *
     * @summary Increment AskSwimm feature usage
     * @param {string} workspaceId The workspace ID
     * @param {AllowanceRequest} allowanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSwimmBackendApi
     */
    apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
        return AskSwimmBackendApiFp(this.configuration).apiAllowanceEndWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Increment AskSwimm feature usage
     * @param {string} workspaceId The workspace ID
     * @param {AllowanceRequest} allowanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSwimmBackendApi
     */
    apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options) {
        return AskSwimmBackendApiFp(this.configuration).apiAllowanceStartWorkspacesWorkspaceIdPost(workspaceId, allowanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Increment feature usage
     * @param {string} workspaceId The workspace ID
     * @param {string} featureId The feature ID to validate for
     * @param {number} usageCount The number usage predicted in the request. Defaults to 1
     * @param {AllowanceRequest1} allowanceRequest1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskSwimmBackendApi
     */
    incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options) {
        return AskSwimmBackendApiFp(this.configuration).incrementCap(workspaceId, featureId, usageCount, allowanceRequest1, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet: (workspaceId, featureId, usageCount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet', 'workspaceId', workspaceId);
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet', 'featureId', featureId);
            // verify required parameter 'usageCount' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet', 'usageCount', usageCount);
            const localVarPath = `/api/allowance/validate/workspaces/{workspaceId}/features/{featureId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)))
                .replace(`{${"usageCount"}}`, encodeURIComponent(String(usageCount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet: (workspaceId, featureId, usageCount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet', 'workspaceId', workspaceId);
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet', 'featureId', featureId);
            // verify required parameter 'usageCount' is not null or undefined
            assertParamExists('apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet', 'usageCount', usageCount);
            const localVarPath = `/api/allowance/validate/workspaces/{workspaceId}/features/{featureId}/{usageCount}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)))
                .replace(`{${"usageCount"}}`, encodeURIComponent(String(usageCount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAgentPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAuthGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppMarketplacePost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/github-app/marketplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/copilot/github-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAgentPost(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotAgentPost(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotAgentPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAuthGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotAuthGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotAuthGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppMarketplacePost(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotGithubAppMarketplacePost(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotGithubAppMarketplacePost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppPost(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiCopilotGithubAppPost(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.apiCopilotGithubAppPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DefaultApiFp(configuration);
    return {
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options) {
            return localVarFp.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} featureId The feature ID to validate for
         * @param {number} usageCount The number usage predicted in the request. Defaults to 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options) {
            return localVarFp.apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAgentPost(options) {
            return localVarFp.apiCopilotAgentPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotAuthGet(options) {
            return localVarFp.apiCopilotAuthGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGet(options) {
            return localVarFp.apiCopilotGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppMarketplacePost(options) {
            return localVarFp.apiCopilotGithubAppMarketplacePost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCopilotGithubAppPost(options) {
            return localVarFp.apiCopilotGithubAppPost(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {string} featureId The feature ID to validate for
     * @param {number} usageCount The number usage predicted in the request. Defaults to 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options) {
        return DefaultApiFp(this.configuration).apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdGet(workspaceId, featureId, usageCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {string} featureId The feature ID to validate for
     * @param {number} usageCount The number usage predicted in the request. Defaults to 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options) {
        return DefaultApiFp(this.configuration).apiAllowanceValidateWorkspacesWorkspaceIdFeaturesFeatureIdUsageCountGet(workspaceId, featureId, usageCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotAgentPost(options) {
        return DefaultApiFp(this.configuration).apiCopilotAgentPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotAuthGet(options) {
        return DefaultApiFp(this.configuration).apiCopilotAuthGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotGet(options) {
        return DefaultApiFp(this.configuration).apiCopilotGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotGithubAppMarketplacePost(options) {
        return DefaultApiFp(this.configuration).apiCopilotGithubAppMarketplacePost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    apiCopilotGithubAppPost(options) {
        return DefaultApiFp(this.configuration).apiCopilotGithubAppPost(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.apiHealthGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['HealthApi.apiHealthGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.healthGet(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['HealthApi.healthGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration, basePath, axios) {
    const localVarFp = HealthApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthGet(options) {
            return localVarFp.apiHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options) {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    apiHealthGet(options) {
        return HealthApiFp(this.configuration).apiHealthGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    healthGet(options) {
        return HealthApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SwimmApi - axios parameter creator
 * @export
 */
export const SwimmApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise: (addEnterpriseRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'addEnterpriseRequest' is not null or undefined
            assertParamExists('addEnterprise', 'addEnterpriseRequest', addEnterpriseRequest);
            const localVarPath = `/api/add-enterprise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(addEnterpriseRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddRepoToWorkspaceIdeRequest} addRepoToWorkspaceIdeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepoToWorkspaceIde: (workspaceId, addRepoToWorkspaceIdeRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addRepoToWorkspaceIde', 'workspaceId', workspaceId);
            // verify required parameter 'addRepoToWorkspaceIdeRequest' is not null or undefined
            assertParamExists('addRepoToWorkspaceIde', 'addRepoToWorkspaceIdeRequest', addRepoToWorkspaceIdeRequest);
            const localVarPath = `/api/workspaces/workspace-mgmt/{workspaceId}/add-repo-ide`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(addRepoToWorkspaceIdeRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddRepoToWorkspaceWebAppRequest} addRepoToWorkspaceWebAppRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepoToWorkspaceWebApp: (workspaceId, addRepoToWorkspaceWebAppRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addRepoToWorkspaceWebApp', 'workspaceId', workspaceId);
            // verify required parameter 'addRepoToWorkspaceWebAppRequest' is not null or undefined
            assertParamExists('addRepoToWorkspaceWebApp', 'addRepoToWorkspaceWebAppRequest', addRepoToWorkspaceWebAppRequest);
            const localVarPath = `/api/workspaces/workspace-mgmt/{workspaceId}/add-repo-webapp`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(addRepoToWorkspaceWebAppRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddUserToWorkspaceRequest} addUserToWorkspaceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToWorkspace: (workspaceId, addUserToWorkspaceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addUserToWorkspace', 'workspaceId', workspaceId);
            // verify required parameter 'addUserToWorkspaceRequest' is not null or undefined
            assertParamExists('addUserToWorkspace', 'addUserToWorkspaceRequest', addUserToWorkspaceRequest);
            const localVarPath = `/api/workspaces/workspaces/{workspaceId}/users/add`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToWorkspaceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWorkspace: (workspaceId, archiveWorkspaceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('archiveWorkspace', 'workspaceId', workspaceId);
            const localVarPath = `/api/workspaces/workspace-mgmt/{workspaceId}/archive`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(archiveWorkspaceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} amount
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddReposToWorkspace: (amount, workspaceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('canAddReposToWorkspace', 'amount', amount);
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('canAddReposToWorkspace', 'workspaceId', workspaceId);
            const localVarPath = `/api/workspaces/workspace-mgmt/{workspaceId}/can-add-repos`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * change the user role
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {string} role
         * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserRole: (workspaceId, userId, role, archiveWorkspaceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('changeUserRole', 'workspaceId', workspaceId);
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserRole', 'userId', userId);
            // verify required parameter 'role' is not null or undefined
            assertParamExists('changeUserRole', 'role', role);
            const localVarPath = `/api/workspaces/workspaces/{workspaceId}/users/{userId}/role/{role}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(archiveWorkspaceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/connections/current-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get all workspace ids of current authenticated user
         * @param {string} [repoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserWorkspaceIds: (repoId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/users/self/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (repoId !== undefined) {
                localVarQueryParameter['repoId'] = repoId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {GetRepoAirGapKeysRequest} getRepoAirGapKeysRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepoAirGapKeys: (getRepoAirGapKeysRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'getRepoAirGapKeysRequest' is not null or undefined
            assertParamExists('getRepoAirGapKeys', 'getRepoAirGapKeysRequest', getRepoAirGapKeysRequest);
            const localVarPath = `/api/airgap/repo-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(getRepoAirGapKeysRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the workspace deleted users
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceDeletedUsers: (workspaceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceDeletedUsers', 'workspaceId', workspaceId);
            const localVarPath = `/api/workspaces/workspaces/{workspaceId}/deleted-users`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUser: (workspaceId, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceUser', 'workspaceId', workspaceId);
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getWorkspaceUser', 'userId', userId);
            const localVarPath = `/api/workspaces/workspaces/{workspaceId}/users/{userId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the workspace users
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUsers: (workspaceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceUsers', 'workspaceId', workspaceId);
            const localVarPath = `/api/workspaces/workspaces/{workspaceId}/users`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {InviteSwimmersRequest} inviteSwimmersRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSwimmers: (workspaceId, inviteSwimmersRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteSwimmers', 'workspaceId', workspaceId);
            // verify required parameter 'inviteSwimmersRequest' is not null or undefined
            assertParamExists('inviteSwimmers', 'inviteSwimmersRequest', inviteSwimmersRequest);
            const localVarPath = `/api/workspaces/invites/{workspaceId}/invite-swimmers`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(inviteSwimmersRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {ResolveJoinRequestRequest} resolveJoinRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveJoinRequest: (workspaceId, resolveJoinRequestRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('resolveJoinRequest', 'workspaceId', workspaceId);
            // verify required parameter 'resolveJoinRequestRequest' is not null or undefined
            assertParamExists('resolveJoinRequest', 'resolveJoinRequestRequest', resolveJoinRequestRequest);
            const localVarPath = `/api/workspaces/invites/{workspaceId}/resolve-join-request`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(resolveJoinRequestRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SendTestEmailRequest} sendTestEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail: (sendTestEmailRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sendTestEmailRequest' is not null or undefined
            assertParamExists('sendTestEmail', 'sendTestEmailRequest', sendTestEmailRequest);
            const localVarPath = `/api/connections/send-test-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(sendTestEmailRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SendTestEmailRequest} sendTestEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestSegmentEvent: (sendTestEmailRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sendTestEmailRequest' is not null or undefined
            assertParamExists('sendTestSegmentEvent', 'sendTestEmailRequest', sendTestEmailRequest);
            const localVarPath = `/api/connections/send-test-segment-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(sendTestEmailRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {string} operation
         * @param {UpdateWorkspaceUserFavoritesRequest} updateWorkspaceUserFavoritesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceUserFavorites: (workspaceId, userId, operation, updateWorkspaceUserFavoritesRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkspaceUserFavorites', 'workspaceId', workspaceId);
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateWorkspaceUserFavorites', 'userId', userId);
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('updateWorkspaceUserFavorites', 'operation', operation);
            // verify required parameter 'updateWorkspaceUserFavoritesRequest' is not null or undefined
            assertParamExists('updateWorkspaceUserFavorites', 'updateWorkspaceUserFavoritesRequest', updateWorkspaceUserFavoritesRequest);
            const localVarPath = `/api/workspaces/workspaces/{workspaceId}/users/{userId}/favoriteRepoIds/{operation}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"operation"}}`, encodeURIComponent(String(operation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkspaceUserFavoritesRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {UpdateWorkspaceUserRecentRepoIdsRequest} [updateWorkspaceUserRecentRepoIdsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceUserRecentRepoIds: (workspaceId, userId, updateWorkspaceUserRecentRepoIdsRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkspaceUserRecentRepoIds', 'workspaceId', workspaceId);
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateWorkspaceUserRecentRepoIds', 'userId', userId);
            const localVarPath = `/api/workspaces/workspaces/{workspaceId}/users/{userId}/recentRepoIds`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkspaceUserRecentRepoIdsRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SwimmApi - functional programming interface
 * @export
 */
export const SwimmApiFp = function (configuration) {
    const localVarAxiosParamCreator = SwimmApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise(addEnterpriseRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addEnterprise(addEnterpriseRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.addEnterprise']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddRepoToWorkspaceIdeRequest} addRepoToWorkspaceIdeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepoToWorkspaceIde(workspaceId, addRepoToWorkspaceIdeRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addRepoToWorkspaceIde(workspaceId, addRepoToWorkspaceIdeRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.addRepoToWorkspaceIde']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddRepoToWorkspaceWebAppRequest} addRepoToWorkspaceWebAppRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepoToWorkspaceWebApp(workspaceId, addRepoToWorkspaceWebAppRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addRepoToWorkspaceWebApp(workspaceId, addRepoToWorkspaceWebAppRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.addRepoToWorkspaceWebApp']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddUserToWorkspaceRequest} addUserToWorkspaceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToWorkspace(workspaceId, addUserToWorkspaceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addUserToWorkspace(workspaceId, addUserToWorkspaceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.addUserToWorkspace']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWorkspace(workspaceId, archiveWorkspaceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.archiveWorkspace(workspaceId, archiveWorkspaceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.archiveWorkspace']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {number} amount
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddReposToWorkspace(amount, workspaceId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.canAddReposToWorkspace(amount, workspaceId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.canAddReposToWorkspace']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * change the user role
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {string} role
         * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserRole(workspaceId, userId, role, archiveWorkspaceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.changeUserRole(workspaceId, userId, role, archiveWorkspaceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.changeUserRole']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.currentUser(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.currentUser']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Get all workspace ids of current authenticated user
         * @param {string} [repoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserWorkspaceIds(repoId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCurrentUserWorkspaceIds(repoId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.getCurrentUserWorkspaceIds']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {GetRepoAirGapKeysRequest} getRepoAirGapKeysRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepoAirGapKeys(getRepoAirGapKeysRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRepoAirGapKeys(getRepoAirGapKeysRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.getRepoAirGapKeys']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Get the workspace deleted users
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceDeletedUsers(workspaceId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getWorkspaceDeletedUsers(workspaceId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.getWorkspaceDeletedUsers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUser(workspaceId, userId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getWorkspaceUser(workspaceId, userId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.getWorkspaceUser']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Get the workspace users
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUsers(workspaceId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getWorkspaceUsers(workspaceId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.getWorkspaceUsers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {InviteSwimmersRequest} inviteSwimmersRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSwimmers(workspaceId, inviteSwimmersRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.inviteSwimmers(workspaceId, inviteSwimmersRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.inviteSwimmers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {ResolveJoinRequestRequest} resolveJoinRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveJoinRequest(workspaceId, resolveJoinRequestRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resolveJoinRequest(workspaceId, resolveJoinRequestRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.resolveJoinRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SendTestEmailRequest} sendTestEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail(sendTestEmailRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendTestEmail(sendTestEmailRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.sendTestEmail']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SendTestEmailRequest} sendTestEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestSegmentEvent(sendTestEmailRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendTestSegmentEvent(sendTestEmailRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.sendTestSegmentEvent']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {string} operation
         * @param {UpdateWorkspaceUserFavoritesRequest} updateWorkspaceUserFavoritesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceUserFavorites(workspaceId, userId, operation, updateWorkspaceUserFavoritesRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateWorkspaceUserFavorites(workspaceId, userId, operation, updateWorkspaceUserFavoritesRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.updateWorkspaceUserFavorites']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {UpdateWorkspaceUserRecentRepoIdsRequest} [updateWorkspaceUserRecentRepoIdsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceUserRecentRepoIds(workspaceId, userId, updateWorkspaceUserRecentRepoIdsRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateWorkspaceUserRecentRepoIds(workspaceId, userId, updateWorkspaceUserRecentRepoIdsRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SwimmApi.updateWorkspaceUserRecentRepoIds']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * SwimmApi - factory interface
 * @export
 */
export const SwimmApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SwimmApiFp(configuration);
    return {
        /**
         *
         * @param {AddEnterpriseRequest} addEnterpriseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterprise(addEnterpriseRequest, options) {
            return localVarFp.addEnterprise(addEnterpriseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddRepoToWorkspaceIdeRequest} addRepoToWorkspaceIdeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepoToWorkspaceIde(workspaceId, addRepoToWorkspaceIdeRequest, options) {
            return localVarFp.addRepoToWorkspaceIde(workspaceId, addRepoToWorkspaceIdeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddRepoToWorkspaceWebAppRequest} addRepoToWorkspaceWebAppRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepoToWorkspaceWebApp(workspaceId, addRepoToWorkspaceWebAppRequest, options) {
            return localVarFp.addRepoToWorkspaceWebApp(workspaceId, addRepoToWorkspaceWebAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {AddUserToWorkspaceRequest} addUserToWorkspaceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToWorkspace(workspaceId, addUserToWorkspaceRequest, options) {
            return localVarFp.addUserToWorkspace(workspaceId, addUserToWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWorkspace(workspaceId, archiveWorkspaceRequest, options) {
            return localVarFp.archiveWorkspace(workspaceId, archiveWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} amount
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddReposToWorkspace(amount, workspaceId, options) {
            return localVarFp.canAddReposToWorkspace(amount, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * change the user role
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {string} role
         * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserRole(workspaceId, userId, role, archiveWorkspaceRequest, options) {
            return localVarFp.changeUserRole(workspaceId, userId, role, archiveWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options) {
            return localVarFp.currentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all workspace ids of current authenticated user
         * @param {string} [repoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserWorkspaceIds(repoId, options) {
            return localVarFp.getCurrentUserWorkspaceIds(repoId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetRepoAirGapKeysRequest} getRepoAirGapKeysRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepoAirGapKeys(getRepoAirGapKeysRequest, options) {
            return localVarFp.getRepoAirGapKeys(getRepoAirGapKeysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the workspace deleted users
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceDeletedUsers(workspaceId, options) {
            return localVarFp.getWorkspaceDeletedUsers(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUser(workspaceId, userId, options) {
            return localVarFp.getWorkspaceUser(workspaceId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the workspace users
         * @param {string} workspaceId The workspace ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceUsers(workspaceId, options) {
            return localVarFp.getWorkspaceUsers(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {InviteSwimmersRequest} inviteSwimmersRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSwimmers(workspaceId, inviteSwimmersRequest, options) {
            return localVarFp.inviteSwimmers(workspaceId, inviteSwimmersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {ResolveJoinRequestRequest} resolveJoinRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolveJoinRequest(workspaceId, resolveJoinRequestRequest, options) {
            return localVarFp.resolveJoinRequest(workspaceId, resolveJoinRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SendTestEmailRequest} sendTestEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail(sendTestEmailRequest, options) {
            return localVarFp.sendTestEmail(sendTestEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SendTestEmailRequest} sendTestEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestSegmentEvent(sendTestEmailRequest, options) {
            return localVarFp.sendTestSegmentEvent(sendTestEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {string} operation
         * @param {UpdateWorkspaceUserFavoritesRequest} updateWorkspaceUserFavoritesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceUserFavorites(workspaceId, userId, operation, updateWorkspaceUserFavoritesRequest, options) {
            return localVarFp.updateWorkspaceUserFavorites(workspaceId, userId, operation, updateWorkspaceUserFavoritesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} workspaceId The workspace ID
         * @param {string} userId The user ID
         * @param {UpdateWorkspaceUserRecentRepoIdsRequest} [updateWorkspaceUserRecentRepoIdsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceUserRecentRepoIds(workspaceId, userId, updateWorkspaceUserRecentRepoIdsRequest, options) {
            return localVarFp.updateWorkspaceUserRecentRepoIds(workspaceId, userId, updateWorkspaceUserRecentRepoIdsRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SwimmApi - object-oriented interface
 * @export
 * @class SwimmApi
 * @extends {BaseAPI}
 */
export class SwimmApi extends BaseAPI {
    /**
     *
     * @param {AddEnterpriseRequest} addEnterpriseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    addEnterprise(addEnterpriseRequest, options) {
        return SwimmApiFp(this.configuration).addEnterprise(addEnterpriseRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {AddRepoToWorkspaceIdeRequest} addRepoToWorkspaceIdeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    addRepoToWorkspaceIde(workspaceId, addRepoToWorkspaceIdeRequest, options) {
        return SwimmApiFp(this.configuration).addRepoToWorkspaceIde(workspaceId, addRepoToWorkspaceIdeRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {AddRepoToWorkspaceWebAppRequest} addRepoToWorkspaceWebAppRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    addRepoToWorkspaceWebApp(workspaceId, addRepoToWorkspaceWebAppRequest, options) {
        return SwimmApiFp(this.configuration).addRepoToWorkspaceWebApp(workspaceId, addRepoToWorkspaceWebAppRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {AddUserToWorkspaceRequest} addUserToWorkspaceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    addUserToWorkspace(workspaceId, addUserToWorkspaceRequest, options) {
        return SwimmApiFp(this.configuration).addUserToWorkspace(workspaceId, addUserToWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    archiveWorkspace(workspaceId, archiveWorkspaceRequest, options) {
        return SwimmApiFp(this.configuration).archiveWorkspace(workspaceId, archiveWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} amount
     * @param {string} workspaceId The workspace ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    canAddReposToWorkspace(amount, workspaceId, options) {
        return SwimmApiFp(this.configuration).canAddReposToWorkspace(amount, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * change the user role
     * @param {string} workspaceId The workspace ID
     * @param {string} userId The user ID
     * @param {string} role
     * @param {ArchiveWorkspaceRequest} [archiveWorkspaceRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    changeUserRole(workspaceId, userId, role, archiveWorkspaceRequest, options) {
        return SwimmApiFp(this.configuration).changeUserRole(workspaceId, userId, role, archiveWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    currentUser(options) {
        return SwimmApiFp(this.configuration).currentUser(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all workspace ids of current authenticated user
     * @param {string} [repoId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    getCurrentUserWorkspaceIds(repoId, options) {
        return SwimmApiFp(this.configuration).getCurrentUserWorkspaceIds(repoId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {GetRepoAirGapKeysRequest} getRepoAirGapKeysRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    getRepoAirGapKeys(getRepoAirGapKeysRequest, options) {
        return SwimmApiFp(this.configuration).getRepoAirGapKeys(getRepoAirGapKeysRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the workspace deleted users
     * @param {string} workspaceId The workspace ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    getWorkspaceDeletedUsers(workspaceId, options) {
        return SwimmApiFp(this.configuration).getWorkspaceDeletedUsers(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {string} userId The user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    getWorkspaceUser(workspaceId, userId, options) {
        return SwimmApiFp(this.configuration).getWorkspaceUser(workspaceId, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the workspace users
     * @param {string} workspaceId The workspace ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    getWorkspaceUsers(workspaceId, options) {
        return SwimmApiFp(this.configuration).getWorkspaceUsers(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {InviteSwimmersRequest} inviteSwimmersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    inviteSwimmers(workspaceId, inviteSwimmersRequest, options) {
        return SwimmApiFp(this.configuration).inviteSwimmers(workspaceId, inviteSwimmersRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {ResolveJoinRequestRequest} resolveJoinRequestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    resolveJoinRequest(workspaceId, resolveJoinRequestRequest, options) {
        return SwimmApiFp(this.configuration).resolveJoinRequest(workspaceId, resolveJoinRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SendTestEmailRequest} sendTestEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    sendTestEmail(sendTestEmailRequest, options) {
        return SwimmApiFp(this.configuration).sendTestEmail(sendTestEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SendTestEmailRequest} sendTestEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    sendTestSegmentEvent(sendTestEmailRequest, options) {
        return SwimmApiFp(this.configuration).sendTestSegmentEvent(sendTestEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {string} userId The user ID
     * @param {string} operation
     * @param {UpdateWorkspaceUserFavoritesRequest} updateWorkspaceUserFavoritesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    updateWorkspaceUserFavorites(workspaceId, userId, operation, updateWorkspaceUserFavoritesRequest, options) {
        return SwimmApiFp(this.configuration).updateWorkspaceUserFavorites(workspaceId, userId, operation, updateWorkspaceUserFavoritesRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} workspaceId The workspace ID
     * @param {string} userId The user ID
     * @param {UpdateWorkspaceUserRecentRepoIdsRequest} [updateWorkspaceUserRecentRepoIdsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwimmApi
     */
    updateWorkspaceUserRecentRepoIds(workspaceId, userId, updateWorkspaceUserRecentRepoIdsRequest, options) {
        return SwimmApiFp(this.configuration).updateWorkspaceUserRecentRepoIds(workspaceId, userId, updateWorkspaceUserRecentRepoIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
