<template>
  <Loader v-if="!showError" class="view-loader" />
  <div v-else>Failed Authenticating with {{ gitProviderDisplayName }}</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { GitProviderInfo, GitProviderName, getLoggerNew, gitProviderUtils } from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { LocalAuthState } from '@/modules/core/composables/git-hosting-authorization';
import { getUserFromDB } from '@/common/utils/user-utils';

const logger = getLoggerNew(__modulename);

const route = useRoute();
const showError = ref(false);
const gitProviderDisplayName = ref('Git Hosting');

async function authenticate(endpoint, body) {
  try {
    logger.info(`Sending local auth request to ${endpoint}`);
    const response = await axios.post(`${endpoint}/token`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    logger.error({ err }, `Failed to authenticate with ${endpoint}: ${err}`);
    return null;
  }
}

onMounted(async () => {
  const {
    authName: enterpriseHostname,
    workspaceId,
    userId,
    authProvider: provider,
    authEndpoint: forceEndpoint,
    authType,
    expires,
  } = JSON.parse(sessionStorage.getItem('authState')) as LocalAuthState;
  if (Date.now() >= new Date(expires).getTime()) {
    showError.value = true;
    logger.error('Auth expired');
    return;
  }

  const user = await getUserFromDB(userId);

  let queryState: string = route.query.state as string;
  if (provider === GitProviderName.GitHub || provider === GitProviderName.GitHubEnterprise) {
    // TODO There is currently double escaping here, queryState is escaped by
    // both encodeURIComponent and URLSearchParams, this is probably not needed.
    const stateParams = new URLSearchParams(decodeURIComponent(queryState));
    queryState = stateParams.get('state_id');
  }

  if (user?.state == null || queryState !== user.state) {
    showError.value = true;
    logger.error('Invalid state');
    return;
  }

  gitProviderDisplayName.value = GitProviderInfo[provider]?.displayName ?? 'Git Hosting';
  const enterprise = {
    workspaceId,
    enterpriseName: enterpriseHostname,
  };

  // When authenticating with GitHub App, we need to make sure we extract the matching config
  if (authType === 'github_app') {
    enterprise['secretName'] = 'GITHUB_APP_AUTH';
  }

  const { data } = await CloudFunctions.fetchEnterpriseAuthConfig(enterprise);
  if (!data) {
    showError.value = true;
    logger.error(
      `Couldn't get config for local authentication: workspace ${workspaceId}, enterprise ${enterpriseHostname}`
    );
    return;
  }

  if (gitProviderUtils.canProviderRefreshTokenLocally(provider)) {
    localStorage.setItem('refreshInfo', JSON.stringify(data));
  }

  const body = {
    clientId: data.clientId,
    clientSecret: data.clientSecret,
    baseUrl: data.baseUrl,
    workspaceId,
    userId,
    provider,
    authType,
    code: route.query.code,
    // Deprecated: Unused and didn't do anything, but required for older onprem-agent/auth-proxy versions
    state: '',
  };

  const authEndpoints = forceEndpoint ? [forceEndpoint] : data.authenticationEndpoints;
  let response;
  if (provider !== GitProviderName.BitbucketDc) {
    for (const endpoint of authEndpoints) {
      response = await authenticate(endpoint, body);
      if (response) {
        break;
      }
    }
  } else {
    response = await gitProviderUtils.authenticateBitbucketDcWithPlugin(data.host, {
      grant_type: 'authorization_code',
      client_id: data.clientId,
      client_secret: data.clientSecret,
      code: route.query.code as string,
      // Deprecated: Unused and didn't do anything, but required for older onprem-agent/auth-proxy versions
      state: '',
    });
  }

  if (response) {
    logger.info(`Authenticated successful for user ${userId} from ${enterpriseHostname}, workspace: ${workspaceId}`);

    await gitProviderUtils.setGitHostingTokenData(enterpriseHostname, response);

    const keepPopupOpen = localStorage.getItem('swimm_keep_popup_open');
    // TODO This won't actually delete the URL from history...
    const myWindow = window.open('', '_self');

    if (keepPopupOpen) {
      logger.debug('Keeping SetGitToken popup open to allow looking at the console and network tabs for debugging');
    } else {
      setTimeout(function () {
        myWindow.close();
      }, 1000);
    }
  } else {
    logger.error(`Failed to locally authenticate user ${userId} from ${enterpriseHostname}, workspace: ${workspaceId}`);
    showError.value = true;
  }
});
</script>
